import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import {
  Container, Typography, FormControl, TextField, Autocomplete, Table, TableBody, Grid,
  TableCell, TableContainer, TableHead, TableRow, Button, Paper, Stack, CircularProgress, Tooltip,
  Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Box,
} from '@mui/material';
import { CheckCircleOutline, HighlightOffOutlined, Add, Remove, } from "@mui/icons-material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { it } from 'date-fns/locale';
import axios from "axios";
import utils from "../../utils";

const AvvisiParcelle = () => {
  const [aziendaSelezionata, setAziendaSelezionata] = useState(null);
  const [avvisi, setAvvisi] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusMessage, setStatusMessage] = useState('');
  const [aziende, setAziende] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [avvisiForm, setAvvisiForm] = useState([{ avviso: '', importo: '', periodo: '', dataInvio: null }]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [searchParams] = useSearchParams();
  const queryAzienda = searchParams.get("u") || null;

  useEffect(() => {
    loadAziende();
  }, []);

  const loadAziende = async () => {
    try {
      const res = await axios.get(`/api/aziendeeop`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAziende(res.data.aziende);
        if (queryAzienda) {
          loadData(queryAzienda).then(() => setAziendaSelezionata(Number(queryAzienda)));
          return;
        }
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  }

  const loadData = async (id) => {
    if (!id) {
      return;
    }
    setLoading(true);
    try {
      const res = await axios.get(`/api/avvisiparcella?id=${id}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAvvisi(res.data.avvisi);
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  }

  const handleChange = (nuovaAzienda) => {
    setAziendaSelezionata(nuovaAzienda);
    loadData(nuovaAzienda);
  };

  const handleCreaAvvisiEsterniDialog = () => {
    setOpenDialog(true);
  };

  const handleFormChange = (index, field, value) => {
    const newForm = [...avvisiForm];
    newForm[index][field] = value;
    setAvvisiForm(newForm);
  };

  const handleAddFormField = (index) => {
    const newForm = [...avvisiForm];
    newForm.splice(index + 1, 0, { avviso: '', importo: '', periodo: '', dataInvio: null });
    setAvvisiForm(newForm);
  };

  const handleRemoveFormField = (index) => {
    const newForm = [...avvisiForm];
    newForm.splice(index, 1);
    setAvvisiForm(newForm);
  };

  const handleConfirmClick = () => {
    setOpenConfirmDialog(true);
  };

  const handleFinalConfirm = () => {
    setOpenConfirmDialog(false);
    setOpenDialog(false);
    handleCreaAvvisiEsterni();
  };

  const handleCreaAvviso  = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`/api/createavvisoparcella`, { id: aziendaSelezionata }, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error, id } = res.data;
      if (success) {
        return window.location.href = '/op/amministrazione/avvisiparcella/detail?id=' + id;
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  }

  const handleCreaAvvisiEsterni = async () => {
    setStatusMessage("");
    setLoading(true);
    try {
      const avvisiClean = [];
      for (const avviso of avvisiForm) {
        const dataMs = avviso.dataInvio.getTime() + 12 * 60 * 60 * 1000;
        const importoCent = Math.round(Number(avviso.importo.replace(',', '.')) * 100);
        if (!importoCent) {
          setStatusMessage("Formato importo non corretto: " + avviso.importo);
          setLoading(false);
          return;
        }
        avvisiClean.push({ ...avviso, dataInvio: dataMs, importo: importoCent });
      }
      const res = await axios.post(`/api/creaavvisiesterni`, { id: aziendaSelezionata, avvisi: avvisiClean }, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAvvisiForm([{ avviso: '', importo: '', periodo: '', dataInvio: null }]);
        loadData(aziendaSelezionata);
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  };  

  const handleRowClick = (avviso) => {
    window.location.href = '/op/amministrazione/avvisiparcella/detail?id=' + avviso.id;
  };

  return (
    <Container maxWidth="xxl">
      <Typography variant="h4" align="center" fontWeight="bold" gutterBottom sx={{ mt: 2 }}>
        Avvisi di Parcella
      </Typography>
      <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
        <Grid item xs={12} sm={10}>
          <FormControl fullWidth>
            <Autocomplete
              id="azienda"
              value={aziendaSelezionata}
              onChange={(event, newValue) => handleChange(newValue)}
              options={aziende.map((az) => az.id)}
              getOptionLabel={(id) => {
                const a = aziende.filter(az => az.id === id)[0];
                return `${a.nome} (${a.id})`;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Azienda"
                  variant="outlined"
                  size="medium"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2} container justifyContent="center" alignItems="center">
          {aziendaSelezionata && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Tooltip title="Crea Avviso di Parcella per tutte le pratiche associate a questa azienda">
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  onClick={handleCreaAvviso}
                >
                  Crea Avviso
                </Button>
              </Tooltip>
              <Box mx={1} /> {/* Aggiungi spazio tra i due bottoni */}
              <Tooltip title="Aggiungi Avvisi manualmente per questa Azienda">
                <IconButton onClick={handleCreaAvvisiEsterniDialog} color="success">
                  <Add />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Grid>
      </Grid>

      {loading ? <Stack sx={{ my: 5 }} alignItems="center">
        <CircularProgress disableShrink />
      </Stack> : <div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ backgroundColor: '#333', color: '#fff', fontWeight: 'bold', width: '10%' }}>Numero</TableCell>
                <TableCell sx={{ backgroundColor: '#333', color: '#fff', fontWeight: 'bold', width: '10%' }}>Periodo</TableCell>
                <TableCell sx={{ backgroundColor: '#333', color: '#fff', fontWeight: 'bold', width: '20%' }}>Ragione Sociale</TableCell>
                <TableCell sx={{ backgroundColor: '#333', color: '#fff', fontWeight: 'bold', width: '15%' }}>Data Scadenza</TableCell>
                <TableCell sx={{ backgroundColor: '#333', color: '#fff', fontWeight: 'bold', width: '15%' }}>Data Pagamento</TableCell>
                <TableCell sx={{ backgroundColor: '#333', color: '#fff', fontWeight: 'bold', width: '10%' }}>Importo</TableCell>
                <TableCell sx={{ backgroundColor: '#333', color: '#fff', fontWeight: 'bold', width: '10%' }} align="center">Confermato</TableCell>
                <TableCell sx={{ backgroundColor: '#333', color: '#fff', fontWeight: 'bold', width: '10%' }} align="center">Pagato</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {avvisi.map((avviso) => (
                <TableRow key={avviso.id} onClick={() => handleRowClick(avviso)} style={{ cursor: 'pointer' }}>
                  <TableCell>{avviso.numero_cp}</TableCell>
                  <TableCell>{avviso.periodo}</TableCell>
                  <TableCell>{avviso.ragione_sociale}</TableCell>
                  <TableCell>{utils.revDate(avviso.data_scadenza)}</TableCell>
                  <TableCell>{utils.revDate(avviso.data_pagamento)}</TableCell>
                  <TableCell>€{(Number(avviso.tot_a_pagare) / 100).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                  <TableCell align="center">
                    {avviso.confermato ? (
                      <CheckCircleOutline color="success" />
                    ) : (
                      <HighlightOffOutlined color="error" />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {avviso.pagato ? (
                      <CheckCircleOutline color="success" />
                    ) : (
                      <HighlightOffOutlined color="error" />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {statusMessage && (
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              mt: 2,
              mb: 2,
              backgroundColor: "#e57373",
              borderColor: "#d32f2f",
              color: "black",
            }}
            role="alert"
          >
            {statusMessage}
          </Paper>
        )}
      </div>}

      {/* Dialog Aggiungi Avviso Manualmente */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="lg" fullWidth
        sx={{ '& .MuiDialog-paper': { height: '600px' } }}>
        <DialogTitle>Aggiungi Avvisi per questa Azienda</DialogTitle>
        <DialogContent sx={{ overflowY: 'auto' }}>
          {avvisiForm.map((form, index) => (
            <Grid container spacing={1} key={index} alignItems="center" sx={{ mt: 2 }}>
              <Grid item xs={3}>
                <TextField
                  label="Avviso"
                  value={form.avviso}
                  onChange={(e) => handleFormChange(index, 'avviso', e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Importo"
                  value={form.importo}
                  onChange={(e) => handleFormChange(index, 'importo', e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Periodo"
                  value={form.periodo}
                  onChange={(e) => handleFormChange(index, 'periodo', e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
                  <DatePicker
                    label="Data Invio"
                    value={form.dataInvio}
                    onChange={(date) => handleFormChange(index, 'dataInvio', date)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={1}>
                {index === 0 ? (
                  <IconButton onClick={() => handleAddFormField(index)}>
                    <Add />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleRemoveFormField(index)}>
                    <Remove />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Annulla
          </Button>
          <Button onClick={handleConfirmClick} color="primary" variant="contained">
            Conferma
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog di conferma finale */}
      <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
        <DialogTitle>Conferma Aggiunta Avvisi</DialogTitle>
        <DialogContent>
          <Typography>Sei sicuro/a di voler aggiungere questi avvisi?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
            Annulla
          </Button>
          <Button onClick={handleFinalConfirm} color="primary">
            Conferma
          </Button>
        </DialogActions>
      </Dialog>

    </Container>
  );
};

export default AvvisiParcelle;
