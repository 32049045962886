import React, { useState, useEffect } from "react";
import {
  Button, Container, Typography,
  Grid, TextField, Box, IconButton, Tooltip, Select, MenuItem,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  FormControl, InputLabel, Card, CardContent, Snackbar, Badge,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from "@mui/material";
import {
  Event, Email, AccessTime, CheckCircleOutline, Pending, Cancel, Delete,
  Map, Timer, Description, Note, CalendarMonth, People, Info, Visibility,
  Download,
} from "@mui/icons-material";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/it';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from 'leaflet';
import axios from 'axios';
import utils from '../../utils';

const ts2Time = ms => {
  if (!ms) {
    return "";
  }
  return new Date(ms).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false, // Usa il formato 24 ore
  });
}

const getMarkers = timbratura => {
  if (!timbratura) {
    return [];
  }
  const markers = [{ title: 'Entrata', ...timbratura.entrata.c }, { title: 'Uscita', ...timbratura.uscita.c }];
  for (let i = 0; i < timbratura.pause.length; i++) {
    markers.push({ title: `Pausa ${i + 1}`, ...timbratura.pause[i].da.c });
    markers.push({ title: `Rientro ${i + 1}`, ...timbratura.pause[i].a.c });
  }
  return markers.filter(m => m.lat && m.lon);
}

const calcolaSommaOreGiorni = eventi => {
  let giorni = 0;
  let minuti = 0;
  for (const evento of eventi) {
    if (evento.payload.timeFrom && evento.payload.timeTo) {
      minuti += (evento.payload.timeTo - evento.payload.timeFrom) / (60 * 1000);
    } else if (evento.payload.dateFrom && evento.payload.dateTo) {
      giorni += 1 + Math.round((new Date(evento.payload.dateTo).getTime() - new Date(evento.payload.dateFrom).getTime()) / (24 * 60 * 60 * 1000));
    }
  }
  if (!giorni && !minuti) {
    return "-";
  }
  if (!giorni) {
    return utils.min2hours(minuti) + " ore";
  }
  if (!minuti) {
    return `${giorni} giorni`;
  }
  return `${giorni} giorni e ${utils.min2hours(minuti)} ore`;
}

const dummyTimbratura = { entrata: {}, uscita: {}, pause: [] };

const TUTTI_DIP = { id: 0, cognome: 'Tutti i dipendenti', nome: '', orario: { giorni: [], tolleranze: { ordinario: 0, straordinario: 0 }, note: "" } }

const AreaDipTimbrature = () => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedEmployee, setSelectedEmployee] = useState(TUTTI_DIP);
  const [employees, setEmployees] = useState([TUTTI_DIP]);
  const [timbrature, setTimbrature] = useState({});
  const [dialogs, setDialogs] = useState({ roundHours: false, approveHours: false, map: false, notes: false, events: false, setHours: false, pauseDetails: false, eventDialog: false, communicationDialog: false, timbratureDialog: false });
  const [editingTimbratura, setEditingTimbratura] = useState(null);
  const [savedIcons, setSavedIcons] = useState({ setHours: false, roundHours: false, approveHours: false });
  const [eventHistory, setEventHistory] = useState([]);
  const [communicationHistory, setCommunicationHistory] = useState([]);
  const [timbratureHistory, setTimbratureHistory] = useState([]);
  const [error, setError] = useState('');

  const [calendarDialog, setCalendarDialog] = useState(false);
  const [eventDetailDialogOpen, setEventDetailDialogOpen] = useState(false);
  const [selectedDayEvents, setSelectedDayEvents] = useState([]);

  const navigate = useNavigate();

  // Dialog Di Conferma Button Salva per Tutti
  const [confirmSaveDialogOpen, setConfirmSaveDialogOpen] = useState(false);

  useEffect(() => {
    loadDipendenti();
  }, []);

  useEffect(() => {
    loadTimbrature(selectedEmployee.id, selectedMonth, selectedYear);
  }, [selectedEmployee.id, selectedMonth, selectedYear]);

  const loadDipendenti = async () => {
    try {
      const res = await axios.get('/api/dipendenti', {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setEmployees([TUTTI_DIP, ...res.data.dipendenti]);
        if (selectedEmployee.id) {
          // aggiorna il dipendente selezionato
          setSelectedEmployee(res.data.dipendenti.filter(dip => dip.id == selectedEmployee.id)[0]);
        }
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
  };

  // se id = 0 carica quelli di tutti filtrati
  const loadTimbrature = async (id, mese, anno) => {
    try {
      const res = await axios.get('/api/timbrature', {
        headers: utils.getAuthHeaders(), params: { id, mese, anno }
      });
      const { success, error } = res.data;
      if (success) {
        setTimbrature(res.data.timbrature);
        setTimbratureHistory(res.data.richiesteTimbrature);
        setEventHistory(res.data.eventi);
        setCommunicationHistory(res.data.comunicazioni);
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
  };

  const saveOrario = async (tutti = false) => {
    try {
      const res = await axios.post('/api/updateprofiloorario', { ...selectedEmployee, tutti }, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        loadDipendenti();
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setConfirmSaveDialogOpen(false);
    handleDialog("setHours", false);
  };

  const saveEditingTimbratura = async () => {
    try {
      const res = await axios.post('/api/updatetimbratura', { timbratura: editingTimbratura, id: selectedEmployee.id }, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        loadTimbrature(selectedEmployee.id, selectedMonth, selectedYear);
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    handleDialog("entrataMod", false);
    handleDialog("uscitaMod", false);
    handleDialog("notes", false);
  };

  const handleTimbraturaApproval = async (id, approve) => {
    try {
      const res = await axios.post('/api/approvetimbratura', { timbratura: id, approve, id: selectedEmployee.id }, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        loadTimbrature(selectedEmployee.id, selectedMonth, selectedYear);
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
  };

  const handleEventApproval = async (id, approve) => {
    try {
      const res = await axios.post('/api/approveevento', { id, approve }, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        loadTimbrature(selectedEmployee.id, selectedMonth, selectedYear);
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
  };

  const handleCommViewed = async () => {
    try {
      const ids = communicationHistory.map(c => Number(c.id));
      const res = await axios.post('/api/setcomunicazionevisualizzata', { ids }, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (!success) {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
  };

  const handleConfirmSaveOpen = () => {
    setConfirmSaveDialogOpen(true);
  };

  const handleConfirmSaveClose = () => {
    setConfirmSaveDialogOpen(false);
  };

  // Conferma Approva Ore
  const [confirmApproveDialogOpen, setConfirmApproveDialogOpen] = useState(false);
  const handleConfirmApproveOpen = () => {
    setConfirmApproveDialogOpen(true);
  };

  const handleConfirmApproveClose = () => {
    setConfirmApproveDialogOpen(false);
  };

  const handleConfirmApprove = () => {
    handleSave("approveHours");
    setConfirmApproveDialogOpen(false);
  };

  // Giorni Festivi nella tabella
  const getFestivoInfo = (anno, mese, giorno) => {
    const festivi = [
      { mese: 0, giorno: 1, nome: 'Capodanno' },
      { mese: 0, giorno: 6, nome: 'Epifania' },
      { mese: 3, giorno: 25, nome: 'Festa della Liberazione' },
      { mese: 4, giorno: 1, nome: 'Festa del Lavoro' },
      { mese: 5, giorno: 2, nome: 'Festa della Repubblica' },
      { mese: 7, giorno: 15, nome: 'Ferragosto' },
      { mese: 10, giorno: 1, nome: 'Ognissanti' },
      { mese: 11, giorno: 8, nome: 'Immacolata Concezione' },
      { mese: 11, giorno: 25, nome: 'Natale' },
      { mese: 11, giorno: 26, nome: 'Santo Stefano' },
    ];

    return festivi.find(festivo => festivo.mese === mese && festivo.giorno === giorno);
  };

  const handleDialog = (type, open) => {
    setDialogs((prev) => ({ ...prev, [type]: open }));
  };

  const updateTimbrature = (updateFn) => {
    setTimbrature((prevTimbrature) => prevTimbrature.map(updateFn));
  };

  const handleSave = (type) => {
    switch (type) {
      case "setHours":
        saveOrario();
        break;
      case "roundHours":
        updateTimbrature((timbratura) => ({
          ...timbratura,
          totaleOre: (Math.round(parseFloat(timbratura.totaleOre) * 60 / 15) * 15) / 60,
        }));
        handleDialog(type, false);
        break;
      case "entrataMod":
        saveEditingTimbratura();
        break;
      case "uscitaMod":
        saveEditingTimbratura();
        break;
      case "notes":
        saveEditingTimbratura();
        break;
      default:
        break;
    }
  };

  const handleCalendarDialog = () => {
    setCalendarDialog(true);
  };

  // Evento sul Calendario
  const getEventsForDay = (day) => {
    const eventi = eventHistory.filter((event) => isEventHappeningAtDay(event, day)).map(event => {
      const dip = employees.filter(dip => dip.id == event.dipendente_id)[0];
      return { ...event, nome: `${dip.cognome} ${dip.nome}` };
    });
    const comunicazioni = communicationHistory.filter((event) => isEventHappeningAtDay(event, day)).map(event => {
      const dip = employees.filter(dip => dip.id == event.dipendente_id)[0];
      return { ...event, nome: `${dip.cognome} ${dip.nome}`, approvato: true };
    });
    return [...eventi, ...comunicazioni];
  };

  const isEventHappeningAtDay = (event, day) => {
    const dayString = `${day.getFullYear()}-${(day.getMonth() + 1).toString().padStart(2, '0')}-${day.getDate().toString().padStart(2, '0')}`;
    return event.payload.dateFrom <= dayString && event.payload.dateTo >= dayString;
  }

  // Colori per Dialog Calendario vari eventi
  const getEventColor = (event) => {
    const color = getColorByType(event.tipo);

    return event.approvato
      ? { backgroundColor: color, color: 'white' }
      : { backgroundColor: 'white', border: `2px solid ${color}`, color };
  };

  const getColorByType = (type) => {
    switch (type) {

      case 'Ferie': return '#28a745'; // Verde
      case 'Permesso': return '#007bff'; // Blu
      case 'Assenza': return '#17a2b8'; // Azzurro

      case 'Malattia': return '#dc3545'; // Rosso
      case 'Infortunio': return '#ff5733'; // Arancione scuro
      case 'Maternità': return '#ff85a2'; // Rosa
      case 'Congedo': return '#ff9f40'; // Arancione
      case 'Donazione Sangue': return '#c0392b'; // Rosso sangue

      // Altri tipi
      default: return '#6c757d'; // Grigio
    }
  };

  const eventColors = {
    'Ferie': '#28a745',
    'Permesso': '#007bff',
    'Assenza': '#17a2b8',
    'Malattia': '#dc3545',
    'Infortunio': '#ff5733',
    'Maternità': '#ff85a2',
    'Congedo': '#ff9f40',
    'Donazione': '#c0392b',
    'Altro': '#6c757d',
  };

  // Dialog del Calendario
  const renderCalendarDialog = () => {
    const firstDayOfMonth = new Date(selectedYear, selectedMonth, 1);
    const daysInPrevMonth = getDaysInMonth(selectedYear, selectedMonth - 1);
    const startDayOfWeek = firstDayOfMonth.getDay() === 0 ? 6 : firstDayOfMonth.getDay() - 1;
    const prevMonthDaysNeeded = startDayOfWeek;

    const prevMonthDays = Array.from({ length: prevMonthDaysNeeded }, (_, i) =>
      new Date(selectedYear, selectedMonth - 1, daysInPrevMonth.length - prevMonthDaysNeeded + i + 1)
    );

    const daysInNextMonth = getDaysInMonth(selectedYear, selectedMonth + 1);
    const lastDayOfMonth = daysInMonth[daysInMonth.length - 1];
    const endDayOfWeek = lastDayOfMonth.getDay() === 0 ? 6 : lastDayOfMonth.getDay() - 1;
    const nextMonthDaysNeeded = 6 - endDayOfWeek;

    const nextMonthDays = Array.from({ length: nextMonthDaysNeeded }, (_, i) =>
      new Date(selectedYear, selectedMonth + 1, i + 1)
    );

    const allDays = [...prevMonthDays, ...daysInMonth, ...nextMonthDays];

    const monthName = new Date(selectedYear, selectedMonth).toLocaleString('it-IT', { month: 'long' });

    return (
      <Dialog
        open={calendarDialog}
        onClose={() => setCalendarDialog(false)}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              Calendario di {monthName} {selectedYear}
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                {Object.entries(eventColors).map(([type, color]) => (
                  <Grid item key={type}>
                    <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', fontSize: '0.65rem' }}>
                      <span style={{ backgroundColor: color, borderRadius: '50%', width: '10px', height: '10px', display: 'inline-block', marginRight: '5px' }}></span>
                      {type}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {Array.from({ length: Math.ceil(allDays.length / 7) }, (_, weekIndex) => (
              <Grid container item xs={12} key={weekIndex} spacing={2} justifyContent="center">
                {allDays.slice(weekIndex * 7, (weekIndex + 1) * 7).map((day) => {
                  const isWeekend = day.getDay() === 0 || day.getDay() === 6;
                  const festivoInfo = getFestivoInfo(day.getFullYear(), day.getMonth(), day.getDate());
                  const isFestivo = !!festivoInfo;
                  const isPrevMonth = day.getMonth() !== selectedMonth && day.getMonth() === (selectedMonth - 1);
                  const isNextMonth = day.getMonth() !== selectedMonth && day.getMonth() === (selectedMonth + 1);

                  return (
                    <Grid item xs={12} sm={3} md={3} lg={1.7} key={day.toISOString()}>
                      <Card
                        sx={{
                          height: '150px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          backgroundColor: isPrevMonth || isNextMonth
                            ? 'rgba(211, 211, 211, 0.5)' // Light grey for previous or next month
                            : isWeekend
                              ? 'rgba(255, 255, 0, 0.19)'
                              : isFestivo
                                ? 'rgba(173, 216, 230, 0.21)'
                                : 'transparent',
                          opacity: isPrevMonth || isNextMonth ? 0.5 : 1,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          const events = getEventsForDay(day);
                          setSelectedDayEvents(events);
                          setEventDetailDialogOpen(true);
                        }}
                      >
                        <CardContent>
                          {festivoInfo && (
                            <Typography variant="caption" color="textSecondary" style={{ fontStyle: 'italic', display: 'block', marginBottom: '4px' }}>
                              {festivoInfo.nome}
                            </Typography>
                          )}
                          <Typography variant="body2" color="textSecondary" style={{ fontStyle: 'italic', display: 'block', marginBottom: '4px' }}>
                            {formatDate(day)}
                          </Typography>
                          {(() => {
                            const events = getEventsForDay(day);
                            const displayedEvents = events.slice(0, 3);
                            return (
                              <>
                                {displayedEvents.map((event, index) => (
                                  <Typography
                                    key={index}
                                    variant="caption"
                                    style={{
                                      borderRadius: '12px',
                                      padding: '2px 6px',
                                      display: 'block', // Mette gli eventi in colonna
                                      fontSize: '0.75rem',
                                      textAlign: 'center',
                                      margin: '3px',
                                      ...getEventColor(event),
                                    }}
                                  >
                                    {selectedEmployee.id ? event.tipo : event.nome}
                                  </Typography>
                                ))}
                                {events.length > 3 && (
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    style={{
                                      fontStyle: 'italic',
                                      fontSize: "14px",
                                      textAlign: 'center', // Centrato
                                      fontWeight: 'bold',  // Più visibile
                                      display: 'block',
                                      marginTop: '1px',
                                    }}
                                  >
                                    ...
                                  </Typography>
                                )}
                              </>
                            );
                          })()}
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCalendarDialog(false)}>Chiudi</Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Render di tutti i dialog Eventi Comunicazioni Timbrature ecc
  const renderDialog = (type, title, content, allowSave = true) => {
    const isSmallDialog = [
      'entrataMod',
      'uscitaMod',
      'notesHistory',
      'notesEvent',
      'notesCommunication'
    ].includes(type);

    return (
      <Dialog
        open={dialogs[type]}
        onClose={() => { setEditingTimbratura(null); handleDialog(type, false) }}
        maxWidth={isSmallDialog ? 'sm' : 'lg'}
        fullWidth={!isSmallDialog}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box mt={1}>
            {content}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setEditingTimbratura(null); handleDialog(type, false) }}>Chiudi</Button>
          {allowSave && <Button onClick={() => handleSave(type)} variant="contained" color="primary">Salva</Button>}
        </DialogActions>
      </Dialog>
    );
  };

  // Dialog dei dettagli degli eventi
  const renderEventDetailDialog = () => (
    <Dialog
      open={eventDetailDialogOpen}
      onClose={() => setEventDetailDialogOpen(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Dettagli</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: '#333', // Colore scuro per l'intestazione
                }}
              >
                <TableCell style={{ color: 'white', fontWeight: 'bold', borderTopLeftRadius: '8px' }}>Tipo</TableCell>
                <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Dipendente</TableCell>
                <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Data Da</TableCell>
                <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Data A</TableCell>
                <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Ora Da</TableCell>
                <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Ora A</TableCell>
                <TableCell style={{ color: 'white', fontWeight: 'bold', borderTopRightRadius: '8px' }}>Note</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedDayEvents.map((event, index) => {

                const isCommunication = event.visualizzata !== undefined;

                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography
                        variant="caption"
                        style={{
                          borderRadius: '12px',
                          padding: '2px 6px',
                          display: 'inline-block',
                          fontSize: '0.75rem',
                          textAlign: 'center',
                          ...getEventColor(event),
                        }}
                      >
                        {event.tipo}
                      </Typography>
                    </TableCell>
                    <TableCell>{event.nome}</TableCell>
                    <TableCell>{event.payload?.dateFrom ? utils.revDate(event.payload.dateFrom) : '-'}</TableCell>
                    <TableCell>{event.payload?.dateTo ? utils.revDate(event.payload.dateTo) : '-'}</TableCell>
                    <TableCell>{event.payload?.timeFrom ? ts2Time(event.payload.timeFrom) : '-'}</TableCell>
                    <TableCell>{event.payload?.timeTo ? ts2Time(event.payload.timeTo) : '-'}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        disabled={!event.payload.note} onClick={() => {
                          setEditingTimbratura({ ...dummyTimbratura, ...event.payload });
                          handleDialog(isCommunication ? "notesCommunication" : "notesEvent", true)
                        }}
                      >
                        <Note fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setEventDetailDialogOpen(false)}>Chiudi</Button>
      </DialogActions>
    </Dialog>
  );

  const formatDate = (date) => {
    const options = { day: 'numeric', weekday: 'short' };
    const formattedDate = date.toLocaleDateString('it-IT', options).replace(/ /g, ' ');
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  };

  const getDaysInMonth = (year, month) => {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const getTimbratureForDay = (day) => {
    return timbrature[day.getDate()] || { entrata: {}, pause: [], uscita: {}, totMinStra: 0, totMinOrd: 0, note: "" };
  };

  const calculateTotalHours = () => {
    return Object.values(timbrature).reduce((total, timbratura) => total + timbratura.totMinOrd, 0);
  };

  const calculateTotalStraordinario = () => {
    return Object.values(timbrature).reduce((total, timbratura) => total + timbratura.totMinStra, 0);
  };

  const calculateTotalPauseForAllDays = () => {
    return Object.values(timbrature).reduce((total, timbratura) => total + calculateTotalPause(timbratura.pause), 0);
  };

  const calculateTotalPause = (pauseArray) => {
    if (!Array.isArray(pauseArray)) {
      return 0;
    }

    return Math.round(pauseArray.reduce((total, pausa) => {
      return total + (pausa.a.t - pausa.da.t) / (1000 * 60); // Convert to minutes
    }, 0));
  };

  const daysInMonth = getDaysInMonth(selectedYear, selectedMonth);

  // Calcola Totale Ore Nel Dialog Profilo Orario
  const calculateWeeklyTotal = () => {
    let totalMinutes = selectedEmployee.orario.giorni.reduce((acc, day) => {
      if (day) {
        const hours = parseInt(day.split(':')[0], 10);
        const minutes = parseInt(day.split(':')[1], 10);
        return acc + (hours * 60) + minutes;
      }
      return acc;
    }, 0);

    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    return `${totalHours}.${remainingMinutes}`;
  };

  return (
    <Container maxWidth="xl">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
        <Typography variant="h4" textAlign="center" mt={3}>Area Dipendente</Typography>
        <Grid container spacing={2} justifyContent="center" alignItems="center" mt={2}>

          {/* Selettore Anno */}
          <Grid item xs={12} sm={2} textAlign="center">
            <Select
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              fullWidth
            >
              {Array.from({ length: new Date().getFullYear() - 2023 }, (_, i) => 2025 + i).map((year) => (
                <MenuItem key={year} value={year}>{year}</MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Selettore Mese */}
          <Grid item xs={12} sm={2} textAlign="center">
            <Select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              fullWidth
            >
              {Array.from({ length: 12 }, (_, i) => i).map((month) => {
                const monthName = new Date(0, month).toLocaleString('default', { month: 'long' });
                const capitalizedMonthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
                return (
                  <MenuItem key={month} value={month}>
                    {capitalizedMonthName}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>

          {/* Selettore Dipendente*/}
          <Grid item xs={12} sm={3} textAlign="center">
            <Select
              value={selectedEmployee.id}
              onChange={(event) => setSelectedEmployee(employees.filter(e => e.id == event.target.value)[0])}
              fullWidth
            >
              {employees.map((employee) => (
                <MenuItem key={employee.id} value={employee.id}>{employee.cognome} {employee.nome}</MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Icone Eventi e Calendario */}
          <Grid item xs={12} sm={2.5} textAlign="center">
            <Tooltip title="Eventi">
              <IconButton color="primary" onClick={() => handleDialog("eventDialog", true)}>
                <Badge badgeContent={eventHistory.filter(event => event.approvato === null).length} color="error">
                  <Event fontSize="large" />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Comunicazioni">
              <IconButton color="primary" onClick={() => { handleDialog("communicationDialog", true); handleCommViewed() }}>
                <Badge badgeContent={communicationHistory.filter(comm => !comm.visualizzata).length} color="error">
                  <Email fontSize="large" />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Timbrature">
              <IconButton color="primary" onClick={() => handleDialog("timbratureDialog", true)}>
                <Badge badgeContent={timbratureHistory.filter(timb => timb.approvata === null).length} color="error">
                  <AccessTime fontSize="large" />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Calendario">
              <IconButton color="primary" onClick={handleCalendarDialog}>
                <CalendarMonth fontSize="large" />
              </IconButton>
            </Tooltip>
          </Grid>

          {/* Icone Profilo Orario e Approvazione Ore e link */}
          <Grid item xs={12} sm={2.5} textAlign="center">
            <Tooltip title="Profilo Orario">
              <IconButton disabled={!selectedEmployee.id} color={savedIcons.setHours ? "success" : "primary"} onClick={() => handleDialog("setHours", true)}>
                <Timer fontSize="large" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Approva Ore">
              <IconButton disabled={!selectedEmployee.id} color={savedIcons.approveHours ? "success" : "primary"} onClick={handleConfirmApproveOpen}>
                <CheckCircleOutline fontSize="large" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Apertura Presenze">
              <IconButton color="primary" onClick={() => navigate('../cli/presenze')}>
                <Description fontSize="large" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Dipendenti">
              <IconButton color="primary" onClick={() => navigate('../cli/amministrazione/dipendenti')}>
                <People fontSize="large" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        {/* Conferma Approvazione Ore */}
        <Dialog
          open={confirmApproveDialogOpen}
          onClose={handleConfirmApproveClose}
        >
          <DialogTitle>Conferma Approvazione</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Sei sicuro di voler approvare le ore? Questaget azione non può essere annullata.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmApproveClose} color="primary">
              Annulla
            </Button>
            <Button onClick={handleConfirmApprove} color="primary" variant="contained">
              Conferma
            </Button>
          </DialogActions>
        </Dialog>

        {/* Tabella Generale Giorni Mese */}
        <Grid item xs={12} mt={2}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={{ backgroundColor: (theme) => theme.palette.grey[900], color: 'white' }}>
                <TableRow>
                  <TableCell align="center"><Typography variant="body1" fontWeight="bold" color="white">Data</Typography></TableCell>
                  <TableCell align="center"><Typography variant="body1" fontWeight="bold" color="white">Entrata</Typography></TableCell>
                  <TableCell align="center"><Typography variant="body1" fontWeight="bold" color="white">Pause</Typography></TableCell>
                  <TableCell align="center"><Typography variant="body1" fontWeight="bold" color="white">Uscita</Typography></TableCell>
                  <TableCell align="center"><Typography variant="body1" fontWeight="bold" color="white">Straord</Typography></TableCell>
                  <TableCell align="center"><Typography variant="body1" fontWeight="bold" color="white">Totale Ore</Typography></TableCell>
                  <TableCell align="center"><Typography variant="body1" fontWeight="bold" color="white">Mappa</Typography></TableCell>
                  <TableCell align="center"><Typography variant="body1" fontWeight="bold" color="white">Note</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {daysInMonth.map((day) => {
                  const timbratura = getTimbratureForDay(day);
                  return (
                    <TableRow
                      key={day.toISOString()}
                      sx={{
                        backgroundColor: day.getDay() === 0 || day.getDay() === 6 ? 'rgba(255, 255, 0, 0.19)' :
                          getFestivoInfo(day.getFullYear(), day.getMonth(), day.getDate()) ? 'rgba(173, 216, 230, 0.21)' : 'transparent'
                      }}
                    >
                      <TableCell align="center">
                        <div style={{ textAlign: 'center' }}>
                          {getFestivoInfo(day.getFullYear(), day.getMonth(), day.getDate()) && (
                            <Typography variant="caption" color="textSecondary" style={{ fontStyle: 'italic', display: 'block', marginBottom: '4px' }}>
                              {getFestivoInfo(day.getFullYear(), day.getMonth(), day.getDate()).nome}
                            </Typography>
                          )}
                          {formatDate(day)}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <span onClick={() => { setEditingTimbratura(timbratura); handleDialog("entrataMod", true) }} style={{ cursor: "pointer" }}>
                          {ts2Time(timbratura.entrata.t)}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title="Dettaglio">
                          <Typography
                            display="inline"
                            style={{ cursor: "pointer" }}
                            onClick={() => { setEditingTimbratura(timbratura); handleDialog("pauseDetails", true) }}
                          >
                            {calculateTotalPause(timbratura.pause) || ""}
                          </Typography>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="center">
                        <span onClick={() => { setEditingTimbratura(timbratura); handleDialog("uscitaMod", true) }} style={{ cursor: "pointer" }}>
                          {ts2Time(timbratura.uscita.t)}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body1" fontWeight="bold">
                          {timbratura.totMinStra ? utils.min2hours(timbratura.totMinStra) : ""}
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        <Typography variant="body1" fontWeight="bold">
                          {timbratura.totMinOrd ? utils.min2hours(timbratura.totMinOrd) : ""}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">

                        <IconButton onClick={() => { setEditingTimbratura(timbratura); handleDialog("map", true) }}>
                          <Map fontSize="small" />
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton disabled={!timbratura.entrata.t} onClick={() => { setEditingTimbratura(timbratura); handleDialog("notes", true) }}>
                          <Note fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow sx={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', fontWeight: 'bold' }}>
                  <TableCell align="center">
                    <Typography variant="body1" fontWeight="bold">Totale</Typography>
                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    <Typography variant="body1" fontWeight="bold">{utils.min2hours(calculateTotalPauseForAllDays())}</Typography>
                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    <Typography variant="body1" fontWeight="bold">{utils.min2hours(calculateTotalStraordinario())}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1" fontWeight="bold">{utils.min2hours(calculateTotalHours())}</Typography>
                  </TableCell>
                  <TableCell colSpan={2}></TableCell>
                </TableRow>

              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {renderDialog("eventDialog", "Eventi", (
          <TableContainer component={Paper} style={{ overflowX: 'auto', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: '#333' }}>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Dipendente</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Tipo</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Data Da</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Data A</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Ora da</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Ora a</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Note</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Documenti</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Approvazione</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eventHistory.filter(event => selectedEmployee.id || event.approvato === null).map((event) => (
                  <TableRow key={event.id}>
                    <TableCell align="center">{employees.filter(e => e.id == event.dipendente_id).map(e => `${e.cognome} ${e.nome}`)[0] || ''}</TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="caption"
                        style={{
                          borderRadius: '12px',
                          padding: '2px 6px',
                          display: 'inline-block',
                          fontSize: '0.75rem',
                          textAlign: 'center',
                          ...getEventColor(event),
                        }}
                      >
                        {event.tipo}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">{utils.revDate(event.payload.dateFrom)}</TableCell>
                    <TableCell align="center">{utils.revDate(event.payload.dateTo)}</TableCell>
                    <TableCell align="center">{ts2Time(event.payload.timeFrom)}</TableCell>
                    <TableCell align="center">{ts2Time(event.payload.timeTo)}</TableCell>
                    <TableCell align="center">
                      <IconButton disabled={!event.payload.note} onClick={() => { setEditingTimbratura({ ...dummyTimbratura, ...event.payload }); handleDialog("notesEvent", true) }}>
                        <Note fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      {event.payload.file ? (
                        <>
                          <Tooltip title="Visualizza">
                            <IconButton disabled={!event.payload.file.name.endsWith('.pdf')} onClick={() => utils.cliOpenPdfInNewTab(event.payload.file.id)} color="primary">
                              <Visibility />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Scarica">
                            <IconButton color="success" onClick={() => utils.cliDownload(event.payload.file.id)}>
                              <Download />
                            </IconButton>
                          </Tooltip>
                        </>) : <div />}
                    </TableCell>
                    <TableCell align="center">
                      {event.approvato && <CheckCircleOutline style={{ color: 'green' }} />}
                      {event.approvato === false && <Cancel style={{ color: 'red' }} />}
                      {event.approvato === null && (
                        <>
                          <IconButton onClick={() => handleEventApproval(event.id, true)}>
                            <CheckCircleOutline style={{ color: 'green' }} />
                          </IconButton>
                          <IconButton onClick={() => handleEventApproval(event.id, false)}>
                            <Cancel style={{ color: 'red' }} />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ), false)}

        {renderDialog("communicationDialog", "Comunicazioni", (
          <TableContainer component={Paper} style={{ overflowX: 'auto', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: '#333' }}>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Dipendente</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Tipo</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Data Da</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Data A</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Ora da</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Ora a</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Note</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Documenti</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {communicationHistory.filter(comm => selectedEmployee.id || !comm.visualizzata).map((comm) => (
                  <TableRow key={comm.id}>
                    <TableCell align="center">{employees.filter(e => e.id == comm.dipendente_id).map(e => `${e.cognome} ${e.nome}`)[0] || ''}</TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="caption"
                        style={{
                          borderRadius: '12px',
                          padding: '2px 6px',
                          display: 'inline-block',
                          fontSize: '0.75rem',
                          textAlign: 'center',
                          backgroundColor: getColorByType(comm.tipo), // Colore pieno
                          color: 'white' // Testo bianco
                        }}
                      >
                        {comm.tipo}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">{utils.revDate(comm.payload.dateFrom)}</TableCell>
                    <TableCell align="center">{utils.revDate(comm.payload.dateTo)}</TableCell>
                    <TableCell align="center">{ts2Time(comm.payload.timeFrom)}</TableCell>
                    <TableCell align="center">{ts2Time(comm.payload.timeTo)}</TableCell>
                    <TableCell align="center">
                      <IconButton disabled={!comm.payload.note} onClick={() => { setEditingTimbratura({ ...dummyTimbratura, ...comm.payload }); handleDialog("notesCommunication", true) }}>
                        <Note fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      {comm.payload.file ? (
                        <>
                          <Tooltip title="Visualizza">
                            <IconButton disabled={!comm.payload.file.name.endsWith('.pdf')} onClick={() => utils.cliOpenPdfInNewTab(comm.payload.file.id)} color="primary">
                              <Visibility />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Scarica">
                            <IconButton color="success" onClick={() => utils.cliDownload(comm.payload.file.id)}>
                              <Download />
                            </IconButton>
                          </Tooltip>
                        </>) : <div />}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ), false)}

        {renderDialog("timbratureDialog", "Richieste di Timbratura", (
          <TableContainer component={Paper} style={{ overflowX: 'auto', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: '#333' }}>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Dipendente</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Data</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Entrata</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Totale Pause</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Uscita</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Note</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Approvazione</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {timbratureHistory.filter(timbratura => selectedEmployee.id || timbratura.approvata === null).map((timbratura) => (
                  <TableRow key={timbratura.id}>
                    <TableCell align="center">{employees.filter(e => e.id == timbratura.dipendente_id).map(e => `${e.cognome} ${e.nome}`)[0] || ''}</TableCell>
                    <TableCell align="center">{formatDate(new Date(timbratura.id))}</TableCell>
                    <TableCell align="center">{ts2Time(timbratura.entrata.t)}</TableCell>
                    <TableCell align="center">{calculateTotalPause(timbratura.pause)}</TableCell>
                    <TableCell align="center">{ts2Time(timbratura.uscita.t)}</TableCell>
                    <TableCell align="center">
                      <IconButton onClick={() => { setEditingTimbratura(timbratura); handleDialog("notesHistory", true) }}>
                        <Note fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      {timbratura.approvata && <CheckCircleOutline style={{ color: 'green' }} />}
                      {timbratura.approvata === false && <Cancel style={{ color: 'red' }} />}
                      {timbratura.approvata === null && (
                        <>
                          <IconButton onClick={() => handleTimbraturaApproval(timbratura.id, true)}>
                            <CheckCircleOutline style={{ color: 'green' }} />
                          </IconButton>
                          <IconButton onClick={() => handleTimbraturaApproval(timbratura.id, false)}>
                            <Cancel style={{ color: 'red' }} />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ), false)}

        {renderCalendarDialog()}
        {renderEventDetailDialog()}

        {renderDialog("setHours", "Imposta Orario", (
          <Box>
            <Typography variant="caption" fontWeight="bold" sx={{ marginBottom: 2, display: 'block' }}>
              Imposta la Tolleranza delle Ore per Eccesso o Difetto
            </Typography>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Tolleranza Ore Ordinarie</InputLabel>
                  <Select
                    value={Math.abs(selectedEmployee.orario.tolleranze.ordinario)}
                    onChange={(e) => setSelectedEmployee(prevEmployee => {
                      return {
                        ...prevEmployee, orario: {
                          ...prevEmployee.orario, tolleranze: {
                            ...prevEmployee.orario.tolleranze,
                            ordinario: Number(e.target.value) * (prevEmployee.orario.tolleranze.ordinario >= 0 ? 1 : -1)
                          }
                        }
                      }
                    })}
                    label="Tolleranza Ore Ordinarie"
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Verso</InputLabel>
                  <Select
                    value={selectedEmployee.orario.tolleranze.ordinario >= 0 ? "Eccesso" : "Difetto"}
                    onChange={(e) => setSelectedEmployee(prevEmployee => {
                      console.log(selectedEmployee)
                      console.log(prevEmployee)
                      return {
                        ...prevEmployee, orario: {
                          ...prevEmployee.orario, tolleranze: {
                            ...prevEmployee.orario.tolleranze,
                            ordinario: (e.target.value == "Difetto" ? -1 : 1) * Math.abs(prevEmployee.orario.tolleranze.ordinario)
                          }
                        }
                      }
                    })}

                    label="Verso"
                  >
                    <MenuItem value="Eccesso">Eccesso</MenuItem>
                    <MenuItem value="Difetto">Difetto</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Tolleranza Ore Straordinarie</InputLabel>
                  <Select
                    value={Math.abs(selectedEmployee.orario.tolleranze.straordinario)}
                    onChange={(e) => setSelectedEmployee(prevEmployee => {
                      return {
                        ...prevEmployee, orario: {
                          ...prevEmployee.orario, tolleranze: {
                            ...prevEmployee.orario.tolleranze,
                            straordinario: Number(e.target.value) * (prevEmployee.orario.tolleranze.straordinario >= 0 ? 1 : -1)
                          }
                        }
                      }
                    })}
                    label="Tolleranza Ore Straordinarie"
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Verso</InputLabel>
                  <Select
                    value={selectedEmployee.orario.tolleranze.straordinario >= 0 ? "Eccesso" : "Difetto"}
                    onChange={(e) => setSelectedEmployee(prevEmployee => {
                      return {
                        ...prevEmployee, orario: {
                          ...prevEmployee.orario, tolleranze: {
                            ...prevEmployee.orario.tolleranze,
                            straordinario: (e.target.value == "Difetto" ? -1 : 1) * Math.abs(prevEmployee.orario.tolleranze.straordinario)
                          }
                        }
                      }
                    })}
                    label="Verso"
                  >
                    <MenuItem value="Eccesso">Eccesso</MenuItem>
                    <MenuItem value="Difetto">Difetto</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Typography variant="caption" fontWeight="bold" sx={{ marginBottom: 2, display: 'block' }}>
              Imposta le Ore per ogni Giorno
            </Typography>
            <Grid container spacing={2} alignItems="center">
              {['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'].map((day, idx) => (
                <Grid item xs={12} md key={day}>
                  <TimePicker
                    label={day}
                    value={dayjs(selectedEmployee?.orario?.giorni?.[idx] || "00:00", "HH:mm")}
                    onChange={(time) => setSelectedEmployee(prevEmployee => {
                      const giorni = prevEmployee.orario.giorni;
                      const formattedTime = time ? time.format('HH:mm') : "00:00";
                      giorni[idx] = formattedTime;
                      return {
                        ...prevEmployee, orario: {
                          ...prevEmployee.orario, giorni
                        }
                      }
                    })}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    ampm={false}
                  />
                </Grid>
              ))}
              <Grid item xs={12} md>
                <TextField
                  label="Totale Ore"
                  type="text"
                  fullWidth
                  value={calculateWeeklyTotal()} // Usa la funzione per calcolare il totale
                  InputProps={{
                    readOnly: true,
                    style: { fontWeight: 'bold' }
                  }}
                />
              </Grid>
            </Grid>
            <Typography variant="caption" fontWeight="bold" sx={{ marginTop: 2, display: 'block' }}>
              Scrivi Eventuali Note
            </Typography>
            <Grid item xs={12} mt={2}>
              <TextField
                label="Note"
                value={selectedEmployee.orario.note}
                onChange={(e) => setSelectedEmployee(prevEmployee => {
                  return {
                    ...prevEmployee, orario: {
                      ...prevEmployee.orario, note: e.target.value
                    }
                  }
                })}
                fullWidth
                multiline
                rows={3}
              />
            </Grid>

            {/* Conferma Salva Profilo Orario Per Tutti */}
            <DialogActions>
              <Tooltip title="Salva questo profilo orario per tutti i dipendenti">
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConfirmSaveOpen} // Mostra il dialog
                  >
                    Salva per Tutti i Dipendenti
                  </Button>
                </span>
              </Tooltip>
            </DialogActions>

            <Dialog
              open={confirmSaveDialogOpen}
              onClose={handleConfirmSaveClose}
            >
              <DialogTitle>Conferma Salvataggio</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Sei sicuro di voler salvare questo profilo orario per <b>tutti i dipendenti</b>?<br />
                  Questa azione non può essere annullata.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleConfirmSaveClose} color="primary">
                  Annulla
                </Button>
                <Button onClick={() => saveOrario(true)} color="primary" variant="contained">
                  Conferma
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        ))}

        {renderDialog("entrataMod", "Modifica Entrata",
          <TimePicker
            label={"Entrata"}
            value={dayjs(ts2Time(editingTimbratura?.entrata.t) || "00:00", "HH:mm")}
            onChange={(time) => setEditingTimbratura(prevTimbratura => {
              const formattedTime = time ? time.format('HH:mm') : "00:00";
              const dateTime = new Date(`${editingTimbratura.id}T${formattedTime}:00`);
              const timestamp = dateTime.getTime();
              return { ...prevTimbratura, entrata: { c: prevTimbratura.entrata.c, t: timestamp } }
            })}
            renderInput={(params) => <TextField {...params} fullWidth />}
            ampm={false}
          />)}

        {renderDialog("uscitaMod", "Modifica Uscita",
          <TimePicker
            label={"Uscita"}
            value={dayjs(ts2Time(editingTimbratura?.uscita.t) || "00:00", "HH:mm")}
            onChange={(time) => setEditingTimbratura(prevTimbratura => {
              const formattedTime = time ? time.format('HH:mm') : "00:00";
              const dateTime = new Date(`${editingTimbratura.id}T${formattedTime}:00`);
              if (formattedTime < ts2Time(editingTimbratura.entrata.t)) {
                dateTime.setDate(dateTime.getDate() + 1);
              }
              const timestamp = dateTime.getTime();
              return { ...prevTimbratura, uscita: { c: prevTimbratura.uscita.c, t: timestamp } }
            })}
            renderInput={(params) => <TextField {...params} fullWidth />}
            ampm={false}
          />)}

        {renderDialog("pauseDetails", "Dettaglio Pause", (
          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={{ backgroundColor: (theme) => theme.palette.grey[900], color: 'white' }}>
                <TableRow>
                  <TableCell align="center"><Typography variant="body1" fontWeight="bold" color="white">Pausa</Typography></TableCell>
                  <TableCell align="center"><Typography variant="body1" fontWeight="bold" color="white">Rientro</Typography></TableCell>
                  <TableCell align="center"><Typography variant="body1" fontWeight="bold" color="white">Totale</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {editingTimbratura?.pause.map((pause, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{ts2Time(pause.da.t)}</TableCell>
                    <TableCell align="center">{ts2Time(pause.a.t)}</TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" fontWeight="bold">
                        {calculateTotalPause([pause])}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ), false)}

        {renderDialog("map", "Mappa", (
          <MapContainer
            center={getMarkers(editingTimbratura).length > 0 ? [getMarkers(editingTimbratura)[0].lat, getMarkers(editingTimbratura)[0].lon] : [45.4642, 9.1900]}
            zoom={getMarkers(editingTimbratura).length > 0 ? 13 : 5}
            style={{ height: "400px", width: "100%" }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {getMarkers(editingTimbratura).map((marker, index) => (
              <Marker
                key={index}
                position={[marker.lat, marker.lon]}
                icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}
              >
                <Popup>
                  <div>
                    <strong>{marker.title}</strong>
                    <p>Entrata: {ts2Time(editingTimbratura.entrata.t)}</p>
                    {editingTimbratura.pause.map((pause, idx) => (
                      <React.Fragment key={idx}>
                        <p>Pausa {idx + 1}: {ts2Time(pause.da.t)}</p>
                        <p>Rientro {idx + 1}: {ts2Time(pause.a.t)}</p>
                      </React.Fragment>
                    ))}
                    <p>Uscita: {ts2Time(editingTimbratura.uscita.t)}</p>
                    <p>Totale Straordinario: {utils.min2hours(editingTimbratura.totMinStra)}</p>
                    <p>Totale Pause: {calculateTotalPause(editingTimbratura.pause)} minuti</p>
                    <p><strong>Totale Ore: {utils.min2hours(editingTimbratura.totMinOrd)}</strong></p>
                  </div>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        ), false)}

        {renderDialog("notes", "Note",
          <TextField label="Note" value={editingTimbratura?.note || ""}
            onChange={(e) => setEditingTimbratura({ ...editingTimbratura, note: e.target.value })} fullWidth multiline rows={12} />)}

        {renderDialog("notesHistory", "Note",
          <TextField label="Note" value={editingTimbratura?.note || ""}
            disabled fullWidth multiline rows={5} />, false)}

        {renderDialog("notesEvent", "Note",
          <TextField label="Note" value={editingTimbratura?.note || ""}
            disabled fullWidth multiline rows={5} />, false)}

        {renderDialog("notesCommunication", "Note",
          <TextField label="Note" value={editingTimbratura?.note || ""}
            disabled fullWidth multiline rows={5} />, false)}

        {error && (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            onClose={() => setError('')}
            message={error}
          />
        )}

      </LocalizationProvider>

      <Paper elevation={3} style={{ padding: '24px', marginTop: '20px', borderRadius: '12px' }}>
        <Typography variant="h6" gutterBottom textAlign="center" style={{ marginBottom: '20px' }}>
          Riepilogo Eventi e Comunicazioni
        </Typography>
        <Grid container spacing={3}>
          {/* Sezione Eventi */}
          <Grid item xs={12} md={6}>
            <Box mb={2}>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" fontWeight="bold">Ferie:</Typography>
                  <Typography variant="body2">
                    {calcolaSommaOreGiorni(eventHistory.filter(event => event.approvato && event.tipo === 'Ferie'))}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" fontWeight="bold">Permessi:</Typography>
                  <Typography variant="body2">
                    {calcolaSommaOreGiorni(eventHistory.filter(event => event.approvato && event.tipo === 'Permesso'))}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" fontWeight="bold">Assenze:</Typography>
                  <Typography variant="body2">
                    {calcolaSommaOreGiorni(eventHistory.filter(event => event.approvato && event.tipo === 'Assenza'))}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" fontWeight="bold">Altri eventi:</Typography>
                  <Typography variant="body2">
                    {calcolaSommaOreGiorni(eventHistory.filter(event => event.approvato && event.tipo === 'Altro'))}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* Sezione Comunicazioni */}
          <Grid item xs={12} md={6}>
            <Box mb={2}>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" fontWeight="bold">Malattia:</Typography>
                  <Typography variant="body2">
                    {calcolaSommaOreGiorni(communicationHistory.filter(event => event.tipo === 'Malattia'))}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" fontWeight="bold">Infortunio:</Typography>
                  <Typography variant="body2">
                    {calcolaSommaOreGiorni(communicationHistory.filter(event => event.tipo === 'Infortunio'))}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" fontWeight="bold">Maternità:</Typography>
                  <Typography variant="body2">
                    {calcolaSommaOreGiorni(communicationHistory.filter(event => event.tipo === 'Maternità'))}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" fontWeight="bold">Congedi:</Typography>
                  <Typography variant="body2">
                    {calcolaSommaOreGiorni(communicationHistory.filter(event => event.tipo === 'Congedo'))}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" fontWeight="bold">Donazioni Sangue:</Typography>
                  <Typography variant="body2">
                    {calcolaSommaOreGiorni(communicationHistory.filter(event => event.tipo === 'Donazione Sangue'))}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Paper>

    </Container>
  );
};

export default AreaDipTimbrature;
