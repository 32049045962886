import React, { useState, useEffect, useRef } from 'react';
import {
  Box, Button, IconButton, TextField, Drawer, Typography, Avatar, Badge, Paper, Divider, useTheme, Tooltip,
  useMediaQuery, Fab, AppBar, Toolbar, CircularProgress, List, ListItem, ListItemAvatar, ListItemText,
  Dialog, DialogTitle, DialogContent, DialogActions, Accordion, AccordionSummary, AccordionDetails, Tabs, Tab,
} from '@mui/material';
import {
  Send as SendIcon, Chat as ChatIcon, AttachFile as AttachFileIcon, PhotoCamera as PhotoCameraIcon,
  Mic as MicIcon, ArrowBack as ArrowBackIcon, Close as CloseIcon, Stop as StopIcon, Shortcut as ShortcutIcon,
  Add as AddIcon, Fullscreen as FullscreenIcon, FullscreenExit as FullscreenExitIcon, ExpandMore as ExpandMoreIcon,
  GetApp as GetAppIcon, Delete as DeleteIcon, MoreVert as MoreVertIcon, SentimentVerySatisfied as SentimentVerySatisfiedIcon,
  Info as InfoIcon, Search as SearchIcon, ContentCopy as ContentCopyIcon, Check as CheckIcon,
} from '@mui/icons-material';
import imageCompression from 'browser-image-compression';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import EmojiPicker from 'emoji-picker-react';
import axios from 'axios';
import utils from '../utils';

// estensioni immagini
const rasterImageFormats = [".jpeg", ".jpg", ".png", ".gif", ".bmp", ".tiff", ".tif", ".webp"];

const Chat = ({ startsOpen }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Stato e variabili
  const [userId, setUserId] = useState(0);
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [isOpen, setIsOpen] = useState(startsOpen === null || startsOpen === undefined ? false : true);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [activeChat, setActiveChat] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState(null);
  const [searchMessageTerm, setSearchMessageTerm] = useState("");
  const [playbackRate, setPlaybackRate] = useState(1);
  const [imagePreview, setImagePreview] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [quotedMessage, setQuotedMessage] = useState(null);
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [newTemplate, setNewTemplate] = useState({ title: "", text: "" });
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [messageInfo, setMessageInfo] = useState(null);
  const [operatorMap, setOperatorMap] = useState({});
  const [openLabelsDialog, setOpenLabelsDialog] = useState(false);
  const [clientLabels, setClientLabels] = useState([]);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const mediaRecorderRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const audioRef = useRef(null); // Per il player audio e per 2x
  const messageRef = useRef([]);
  const chatRefreshIntervalRef = useRef(null); // Per stoppare il caricamento messaggi

  // Funzioni di gestione dello stato
  const loadUser = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, me } = res.data;
        if (success) {
          setUserId(me.id);
          setUsername(me.op ? me.nome : me.ragione_sociale);
          setRole(me.op ? 'operator' : 'client');
          setTimeout(() => loadChats(true), 500);
          if (me.op) {
            loadTemplates();
            loadOps();
          }
        }
      }
    } catch (_) { }
  }

  const loadOps = async () => {
    try {
      const res = await axios.get(`/api/listaop`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error, ops } = res.data;
      if (success) {
        const mappa = {}
        for (const op of ops) {
          mappa[op.id] = op.nome;
        }
        setOperatorMap(mappa);
      } else {
        console.error(error);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const loadChats = async (openStartingChat = false) => {
    try {
      const res = await axios.get(`/api/chats`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        const sortedClients = res.data.chats.sort((a, b) => {
          if (b.lastMessageAt == a.lastMessageAt) {
            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
          }
          return Number(b.lastMessageAt) - Number(a.lastMessageAt);
        });
        setClients(sortedClients);
        const newUnreadCount = res.data.chats.map(c => c.unreadCount).reduce((a, b) => a + b, 0);
        setUnreadCount(newUnreadCount);
        if (startsOpen && openStartingChat) {
          const cl = sortedClients.filter(c => c.id == startsOpen)[0];
          if (cl) {
            handleOpenChat(cl);
          }
        }
      } else {
        console.error(error);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const fetchMessages = async (old = false) => {
    try {
      const res = await axios.get(`/api/chat${old ? '?pivot=' + messages[0].index : ''}`, {
        params: { to: activeChat.id }, headers: utils.getAuthHeaders()
      });
      const { success, error } = res.data;
      if (success) {
        const messs = res.data.messaggi;
        if (messs.length) {
          if (old) {
            messageRef.current = [...messs, ...messageRef.current]; // Aggiorna il valore della ref
            setMessages(messageRef.current);
            if (chatRefreshIntervalRef.current) {
              clearInterval(chatRefreshIntervalRef.current);
              chatRefreshIntervalRef.current = null;
            }
          } else {
            const latestIndex = messageRef.current.length ? messageRef.current[messageRef.current.length - 1].index : -1;
            if (messs[messs.length - 1].index > latestIndex) {
              messageRef.current = messs; // Aggiorna il valore della ref
              setMessages(messs);
            }
          }
        }
      } else {
        console.error(error);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
    setIsLoading(false);
  };

  const loadTemplates = async () => {
    try {
      const res = await axios.get(`/api/chattemplates`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setTemplates(res.data.templates);
      } else {
        console.error(error);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const loadClientLabels = async (clientId) => {
    if (clientId <= 0) {
      return;
    }
    try {
      const res = await axios.get(`/api/getuser?id=${clientId}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, user } = res.data;
      if (success) {
        setClientLabels(user.etichette.split(',').filter(e => e.length));
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Funz Invio Messaggi---------------------------------------
  const handleSendMessage = async () => {
    if (!message.trim()) return;
    if (isLoading) return;

    const payload = {
      testo: message,
      to: activeChat.id,
      quotedMessage: quotedMessage ? {
        nome: quotedMessage.nome,
        testo: quotedMessage.testo,
        index: quotedMessage.index,
      } : null,
    };

    const formdata = new FormData();
    formdata.append("data", JSON.stringify(payload));

    setMessage("");
    setQuotedMessage(null); // Reset the quoted message after sending
    setIsLoading(true); // Start loading

    try {
      const res = await axios.post('/api/chat', formdata, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, error } = res.data;
      if (success) {
        setMessages(prev => {
          const newMessages = [...prev, {
            nome: username,
            utente_id: userId,
            tipo: 'text',
            testo: payload.testo,
            quotedMessage: payload.quotedMessage,
            index: messages.length ? messages[messages.length - 1].index + 1 : 0,
            timestamp: Date.now()
          }];
          return newMessages.length > 30 ? newMessages.slice(1) : newMessages;
        });
      } else {
        console.error(error);
      }
      setIsLoading(false); // End loading after successful send
    } catch (error) {
      console.error('Error sending message:', error);
      setIsLoading(false); // End loading on error
    }
  };

  // Funz Invio Immagini
  const handleSendImage = async (file) => {
    if (!file) return;
    if (isLoading) return;

    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
        initialQuality: 0.90, // Aumenta la qualità iniziale
      };

      setIsLoading(true);
      const compressedFile = await imageCompression(file, options);
      const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(',')[1]);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });

      const base64Image = await toBase64(compressedFile);
      const payload = {
        testo: base64Image,
        to: activeChat.id,
        tipo: 'image',
      };

      const formdata = new FormData();
      formdata.append("data", JSON.stringify(payload));

      try {
        const res = await axios.post('/api/chat', formdata, {
          headers: {
            ...utils.getAuthHeaders(),
            "Content-Type": "multipart/form-data",
          },
        });
        const { success, error } = res.data;
        if (success) {
          setMessages(prev => {
            const newMessages = [...prev, {
              nome: username,
              utente_id: userId,
              testo: payload.testo,
              tipo: 'image',
              index: messages.length ? messages[messages.length - 1].index + 1 : 0,
              timestamp: Date.now()
            }];
            return newMessages.length > 30 ? newMessages.slice(1) : newMessages;
          });
        } else {
          console.error(error);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error sending image:', error);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error compressing or converting image to base64:', error);
    }
  };

  // Anteprima Immagini in Dialog
  const handleImagePreview = (imageSrc) => {
    setImagePreview(imageSrc);
  };

  const fetchImagePreview = async (id) => {
    try {
      const response = await axios.get(`/api/scarica${role === 'client' ? 'doccliente' : ''}?id=${id}`, {
        headers: utils.getAuthHeaders(),
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "image" });
      const objectUrl = URL.createObjectURL(blob);

      setImagePreview(objectUrl);

    } catch (err) {
      console.error(err.message);
    }
  };

  const handleCloseImagePreview = () => {
    setImagePreview(null);
  };

  // Funz Allega File
  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (isLoading) return;

    const payload = {
      tipo: 'file',
      testo: file.name,
      to: activeChat.id,
    };

    const formdata = new FormData();
    formdata.append("data", JSON.stringify(payload));
    formdata.append("file", file);

    setMessage("");
    setIsLoading(true); // Start loading

    try {
      const res = await axios.post('/api/chat', formdata, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, error, file_id } = res.data;
      if (success) {
        setMessages(prev => {
          const newMessages = [...prev, {
            nome: username,
            tipo: 'file',
            utente_id: userId,
            testo: `${file_id}|${payload.testo}`,
            index: messages.length ? messages[messages.length - 1].index + 1 : 0,
            timestamp: Date.now()
          }];
          return newMessages.length > 30 ? newMessages.slice(1) : newMessages;
        });
      } else {
        console.error(error);
      }
      setIsLoading(false); // End loading after successful send
    } catch (error) {
      console.error('Error sending message:', error);
      setIsLoading(false); // End loading on error
    }
  };

  // Funzione reg Audio
  const handleVoiceRecord = async () => {
    if (isRecording) {
      mediaRecorderRef.current?.stop();
      setIsRecording(false);
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      const chunks = [];

      mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(chunks, { type: 'audio/wav' });

        // Funzione per convertire il Blob in Base64
        const toBase64 = (blob) => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result.split(',')[1]);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });

        try {
          const base64Audio = await toBase64(audioBlob);
          setIsLoading(true); // Inizia il caricamento
          const payload = {
            testo: base64Audio,
            to: activeChat.id,
            tipo: 'audio',
          };

          const formdata = new FormData();
          formdata.append("data", JSON.stringify(payload));

          // Invia l'audio codificato in Base64 al backend
          try {
            const res = await axios.post('/api/chat', formdata, {
              headers: {
                ...utils.getAuthHeaders(),
                "Content-Type": "multipart/form-data",
              },
            });
            const { success, error } = res.data;
            if (success) {
              setMessages(prev => {
                const newMessages = [...prev, {
                  nome: username,
                  utente_id: userId,
                  testo: payload.testo,
                  tipo: 'audio',
                  index: messages.length ? messages[messages.length - 1].index + 1 : 0,
                  timestamp: Date.now()
                }];
                return newMessages.length > 30 ? newMessages.slice(1) : newMessages;
              });
            } else {
              console.error(error);
            }
            setIsLoading(false); // Termina il caricamento dopo l'invio
          } catch (error) {
            console.error('Error sending audio:', error);
            setIsLoading(false); // Termina il caricamento in caso di errore
          }
        } catch (error) {
          console.error('Error converting audio to base64:', error);
        }
      };

      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error recording audio:', error);
    }
  };

  // Funzione Elimina Messaggi
  const handleDeleteMessageConfirm = async () => {
    try {
      const res = await axios.post('/api/chatdelete', { to: activeChat.id, index: messageToDelete.index }, {
        headers: utils.getAuthHeaders()
      });
      const { success, error } = res.data;
      if (success) {
        setMessages(prevMessages => {
          const newMessages = [];
          for (const message of prevMessages) {
            if (message.index != messageToDelete.index) {
              newMessages.push(message);
            } else {
              newMessages.push({
                ...message,
                testo: 'Messaggio Eliminato',
                tipo: 'text',
              });
            }
          }
          return newMessages;
        });
        setMessageToDelete(null);
        setOpenDeleteDialog(false);
      } else {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Funzione Apertura e chiusura Chat
  const handleOpenChat = async (cl) => {
    setActiveChat(cl);
    setClients(prevClients =>
      prevClients.map(c =>
        c.id === cl.id ? { ...c, unreadCount: 0 } : c
      )
    );
    const unreadCountNew = clients.map(c => c.id === cl.id ? 0 : c.unreadCount).reduce((a, b) => a + b, 0);
    setUnreadCount(unreadCountNew);
    try {
      const res = await axios.post('/api/chatletta', { to: cl.id }, {
        headers: utils.getAuthHeaders()
      });
      const { success, error } = res.data;
      if (!success) {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
    loadClientLabels(cl.id); // Carica le etichette del cliente
  };

  const openChat = () => {
    if (role == 'client') {
      if (clients[0]) {
        setIsOpen(true);
        handleOpenChat(clients[0]);
      }
    } else {
      setIsOpen(true);
    }
  }

  const closeChat = () => {
    setIsOpen(false);
    setActiveChat(null);
  }

  // Funzione Per scrollare infondo alla chat
  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
    // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Funzione Cerca nella Chat
  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };
  const filteredMessages = messages.filter(message =>
    message.testo.toLowerCase().includes(searchMessageTerm.toLowerCase())
  );

  // Funzione Data Messaggi
  const groupMessagesByDate = (messages) => {
    return messages.reduce((acc, message) => {
      const date = new Date(Number(message.timestamp)).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(message);
      return acc;
    }, {});
  };
  const groupedMessages = groupMessagesByDate(filteredMessages);

  // Funzione per il cambio dell avatar
  const handleAvatarImageChange = async (event, chatId = null) => {
    const file = event.target.files[0];
    if (file) {
      try {
        // Creare un oggetto Image per leggere il file
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = async () => {
          // Impostare la larghezza e altezza minima
          const minSize = 300;
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          // Calcolare il ridimensionamento in modo che la dimensione minima sia 80px
          let width = img.width;
          let height = img.height;
          if (width < minSize && height < minSize) {
            // non comprimere
          } else if (width < height) {
            height = (minSize / width) * height;
            width = minSize;
          } else {
            width = (minSize / height) * width;
            height = minSize;
          }
          // Impostare il canvas con le dimensioni calcolate
          canvas.width = width;
          canvas.height = height;
          // Disegnare l'immagine ridimensionata nel canvas
          ctx.drawImage(img, 0, 0, width, height);
          // Comprimere l'immagine (JPEG con qualità 80) e convertirla in base64
          const compressedBase64 = canvas.toDataURL('image/jpeg', 0.8);
          // Inviare la base64 come JSON al server
          const res = await axios.post('/api/avatar', {
            avatar: compressedBase64.split(',')[1], chatId
          }, {
            headers: {
              ...utils.getAuthHeaders(),
              'Content-Type': 'application/json',
            },
          });

          const { success, error } = res.data;
          if (success) {
            window.location.reload();
          } else {
            console.error(error);
          }
        };

        // Gestire gli errori nel caricamento dell'immagine
        img.onerror = (error) => {
          console.error('Error loading image:', error);
        };
      } catch (error) {
        console.error('Error processing avatar:', error);
      }
    }
  };

  // Gestione Chat Recenti e Contatti Espandibili
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // UseEffect-------------------------------------------------
  // Caricamento dell'utente al montaggio del componente
  useEffect(() => {
    loadUser();
  }, []);

  // Caricamento delle chat a intervalli regolari
  useEffect(() => {
    if (!userId) {
      return;
    }
    const intervalId = setInterval(() => {
      loadChats();
    }, 15000);
    return () => clearInterval(intervalId);
  }, [userId])

  // Scroll automatico verso il basso quando i messaggi cambiano
  useEffect(() => {
    if (messages && messages.length <= 30) {
      scrollToBottom();
    }
  }, [messages]);

  // Reset dei messaggi selezionati e scroll verso il basso quando la chat viene chiusa
  useEffect(() => {
    if (!isOpen) {
      setSelectedMessages([]); // Reset selected messages when closing the chat
      scrollToBottom();
    }
  }, [isOpen]);

  // Caricamento dei messaggi a intervalli regolari quando una chat è attiva
  useEffect(() => {
    if (activeChat) {
      messageRef.current = [];
      setMessages([]);
      setIsLoading(true);
      fetchMessages();
      if (chatRefreshIntervalRef.current) {
        clearInterval(chatRefreshIntervalRef.current);
      }
      chatRefreshIntervalRef.current = setInterval(fetchMessages, 3000);
    }
    return () => {
      if (chatRefreshIntervalRef.current) {
        clearInterval(chatRefreshIntervalRef.current);
        chatRefreshIntervalRef.current = null;
      }
    };
  }, [activeChat]);

  // Aggiornamento del riferimento ai messaggi quando i messaggi cambiano
  useEffect(() => {
    messageRef.current = messages;
  }, [messages]);

  // Esapande i recenti all apertura della chat se ci sono notifiche
  useEffect(() => {
    if (unreadCount > 0 && role === 'operator') {
      setExpanded('recentChats');
    }
  }, [unreadCount, role]);

  // Aggiornamento del titolo della scheda del browser con il numero di messaggi non letti
  useEffect(() => {
    if (unreadCount > 0) {
      document.title = `🔔 (${unreadCount}) Studio Romeo`;
    } else {
      document.title = 'Studio Romeo';
    }
  }, [unreadCount]);

  const filteredClients = clients.filter(a => a.name.toLowerCase()
    .replace(/[^\p{L}\p{N}]/gu, '')
    .includes(searchTerm.toLowerCase().replace(/[^\p{L}\p{N}]/gu, '')));

  if (!userId) {
    return null;
  }

  // Dot chat da gestire e gestita
  const HandleDot = ({ client, clients, setClients }) => {
    const handleDotClick = async (e) => {
      e.stopPropagation();
      try {
        const res = await axios.post('/api/chatdagestire', {
          id: client.id,
          daGestire: !client.daGestire
        }, {
          headers: utils.getAuthHeaders()
        });

        if (res.data.success) {
          setClients(clients.map(c =>
            c.id === client.id ? { ...c, daGestire: !c.daGestire } : c
          ));
        }
      } catch (error) {
        console.error('Error updating daGestire flag:', error);
      }
    };

    return (
      <Tooltip title={client.daGestire ? "Da gestire" : "Gestito"}>
        <Box
          sx={{
            width: 30,
            height: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={handleDotClick}
        >
          <Box
            sx={{
              width: 12,
              height: 12,
              borderRadius: '50%',
              backgroundColor: client.daGestire ? 'orange' : 'lightgray',
              border: '0px solid grey', // Optional: Add a border for better visibility
            }}
          />
        </Box>
      </Tooltip>
    );
  };

  // handler per gestire il trascinamento dei file nella chat
  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      const confirmed = window.confirm("Sei sicuro di voler inviare questo file?");
      if (confirmed) {
        if (file.type.startsWith('image/')) {
          handleSendImage(file);
        } else {
          handleFileUpload({ target: { files } });
        }
      }
    }
    setIsDragging(false); // Rimuovi il messaggio dopo il rilascio
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  // Funzione per incollare immagini direttamente nel campo input
  const handlePaste = async (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.clipboardData;
    const items = clipboardData.items;
    let pastedText = '';

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.indexOf('image') === 0) {
        const file = item.getAsFile();
        if (file) {
          handleSendImage(file);
        }
      } else if (item.type.indexOf('text') === 0) {
        pastedText = await new Promise((resolve) => item.getAsString(resolve));
      }
    }

    if (pastedText) {
      setMessage((prevMessage) => prevMessage + pastedText);
    }
  };

  // Funzione per aggiungere un nuovo template
  const handleAddTemplate = async () => {
    if (newTemplate.title.trim() && newTemplate.text.trim()) {
      try {
        const res = await axios.post(`/api/chattemplates`, { templates: [...templates, newTemplate] }, {
          headers: utils.getAuthHeaders(),
        });
        const { success, error } = res.data;
        if (success) {
          setTemplates([...templates, newTemplate]);
          setNewTemplate({ title: "", text: "" });
        } else {
          console.error(error);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Funzione per eliminare un template
  const handleDeleteTemplate = async (index) => {
    const newTemplates = templates.filter((_, i) => i !== index);
    try {
      const res = await axios.post(`/api/chattemplates`, { templates: newTemplates }, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setTemplates(newTemplates);
      } else {
        console.error(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Funzione per selezionare un template
  const handleSelectTemplate = (template) => {
    setMessage((prevMessage) => prevMessage + template.replace(/\n/g, '\n'));
    setOpenTemplateDialog(false);
  };

  // Funzione per troncare il testo
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  // Funzione Info ora e visto messaggi
  const handleInfoMessage = async (letture) => {
    setMessageInfo(letture);
    setOpenInfoDialog(true);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // Funzione per contrassegnare come lette le chat
  const handleMarkAllAsRead = async () => {
    try {
      const res = await axios.post('/api/markallasread', {}, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setClients(prevClients =>
          prevClients.map(client => ({ ...client, unreadCount: 0 }))
        );
        setUnreadCount(0);
      } else {
        console.error(error);
      }
    } catch (error) {
      console.error('Errore durante la marcatura di tutti i messaggi come letti:', error);
    }
  };

  // Funzione per i Bubble dei Messaggi------------------------------------------
  const MessageBubble = ({ message }) => {
    const isOwn = message.utente_id === userId;
    const isSelected = selectedMessages.includes(message.index);

    const toggleSelectMessage = () => {
      setSelectedMessages(prev =>
        prev.includes(message.index)
          ? prev.filter(id => id !== message.index)
          : [...prev, message.index]
      );
    };

    const handleQuoteMessage = () => {
      if (message.tipo === 'text') {
        setQuotedMessage(message);
      } else {
        let testo;
        if (message.tipo === 'image') {
          testo = 'Immagine';
        } else if (message.tipo === 'audio') {
          testo = "Audio";
        } else if (message.tipo === 'file') {
          testo = "File";
        } else {
          testo = message.tipo;
        }
        setQuotedMessage({ index: message.index, nome: message.nome, testo });
      }
      setIsOpen(true); // Apri la chat se non è già aperta
    };

    // Funzione Elimina Messaggi con Dialog
    const handleDeleteMessage = () => {
      setMessageToDelete(message);
      setOpenDeleteDialog(true);
    };

    if (role !== 'operator' && role !== 'client') {
      return null;
    }

    // Colori Nomi Bubble
    const senderColor = isOwn ? '#1f618d' : '#ADD8E6';
    const forceAlignToRight = role === 'operator' && activeChat && activeChat.id > 0 && activeChat.id != message.utente_id;

    return (
      <Box
        id={`message-${message.index}`}
        sx={{
          display: 'flex',
          justifyContent: (isOwn || forceAlignToRight) ? 'flex-end' : 'flex-start',
          mb: 2,
          alignItems: 'center', // Align icons with message
        }}
        onClick={toggleSelectMessage} // Toggle selection on click
      >
        {!isOwn && (
          <Avatar
            sx={{ mr: isSelected && forceAlignToRight ? 3 : 1, width: 30, height: 30 }}
            src={role === 'client' ? './avatar.png' : `/api/avatar?id=${message.utente_id}`}
          />
        )}

        {/* Bubble Message della chat */}
        <Paper
          elevation={1}
          sx={{
            maxWidth: '70%',
            p: 1,
            px: 2, // Mantieni il padding orizzontale
            py: 1, // Riduci il padding verticale
            backgroundColor: isOwn ? '#6dadff' : '#4076bf', // Colore mittente lightblue e destinatario DarkCyan
            color: isOwn ? 'white' : 'white', // Colore del testo
            borderRadius: 5,
            position: 'relative',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
          }}
        >

          {role === 'operator' && (
            <Typography
              variant="caption"
              sx={{
                fontWeight: 'bold',
                color: senderColor,
                opacity: 0.7,
                alignSelf: 'flex-start',
              }}
            >
              {message.nome}
            </Typography>
          )}

          {/* Invio Messaggi tipo testo */}
          {message.tipo === 'text' && (
            <>
              {message.quotedMessage && (
                <Paper
                  elevation={1}
                  onClick={() => {
                    const quotedMessageElement = document.getElementById(`message-${message.quotedMessage.index}`);
                    if (quotedMessageElement) {
                      quotedMessageElement.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                  sx={{
                    maxWidth: 'calc(100% - 20px)', // Evita di toccare i bordi
                    p: 1,
                    px: 2,
                    py: 1,
                    backgroundColor: (messages.filter(m => m.index == message.quotedMessage.index)[0] || {}).utente_id == userId ? '#6dadff' : '#4076bf',
                    color: 'white', // Colore del testo
                    borderRadius: 3,
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                    mb: 1, // Margine inferiore per separare dal messaggio principale
                  }}
                >
                  {role === 'operator' && (
                    <Typography variant="caption" sx={{ fontWeight: 'bold', color: '#ADD8E6' }}>
                      {message.quotedMessage.nome}
                    </Typography>
                  )}
                  <Typography variant="body2">
                    {message.quotedMessage.testo.length > (isMobile ? 20 : 100)
                      ? message.quotedMessage.testo.substring(0, isMobile ? 20 : 100) + '...'
                      : message.quotedMessage.testo}
                  </Typography>
                </Paper>
              )}
              <pre
                style={{
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                  fontFamily: "Arial, Helvetica, sans-serif",
                  fontSize: '15px', marginTop: '0px', marginBottom: '0px',
                  fontStyle: message.testo == 'Messaggio Eliminato' ? 'italic' : 'inherit',
                }}>
                {message.testo.split(/(https?:\/\/\S+)/).reduce((acc, part) => {
                  if (/https?:\/\//.test(part)) acc.push(part);
                  else if (part.trim()) acc[acc.length - 1] && !/https?:\/\//.test(acc[acc.length - 1]) ? acc[acc.length - 1] += " " + part : acc.push(part);
                  return acc;
                }, []).map(str => {
                  if (str.includes('https://') || str.includes('http://')) {
                    return (<a href={str} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
                      {str}
                    </a>)
                  } else {
                    return <span>{str}</span>;
                  }
                })}
              </pre>
            </>
          )}

          {/* Invio Immagini e Fotocamera con Download */}
          {message.tipo === 'image' && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <img
                src={`data:image/jpeg;base64,${message.testo}`}
                alt="Immagine inviata"
                loading="lazy"
                style={{ maxWidth: '100%', maxHeight: '300px', borderRadius: '8px', cursor: 'pointer' }}
                onClick={() => handleImagePreview(`data:image/jpeg;base64,${message.testo}`)}
              />
            </Box>
          )}

          {/* Invio Audio con Download */}
          {message.tipo === 'audio' && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
              {/* Player audio con riferimento */}
              <audio
                ref={audioRef}
                controls
                style={{ flexGrow: 1 }}
                onPlay={() => {
                  if (audioRef.current) {
                    audioRef.current.playbackRate = playbackRate; // Imposta la velocità attuale
                  }
                }}
              >
                <source src={`data:audio/wav;base64,${message.testo}`} type="audio/wav" />
              </audio>

              {/* Pulsante cliccabile per cambiare velocità */}
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  const rates = [0.5, 1, 1.5, 2];
                  const currentIndex = rates.indexOf(playbackRate);
                  const newRate = rates[(currentIndex + 1) % rates.length];
                  setPlaybackRate(newRate);
                  if (audioRef.current) {
                    audioRef.current.playbackRate = newRate;
                  }
                }}
                sx={{
                  backgroundColor: '#6495ED',
                  color: 'white',
                  '&:hover': { backgroundColor: '#4169E1' },
                  borderRadius: '50%',
                  minWidth: 'auto',
                  padding: '1px',
                  fontSize: '10px',
                  width: '30px',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {`${playbackRate}x`}
              </Button>
            </Box>
          )}

          {/* Allega file con Preview e Download */}
          {message.tipo === 'file' && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <AttachFileIcon fontSize="small" />
              <Typography
                onClick={() => {
                  if (message.testo.split('|')[1].toLowerCase().endsWith('.pdf')) {
                    role === 'client'
                      ? utils.cliOpenPdfInNewTab(message.testo.split('|')[0])
                      : utils.opOpenPdfInNewTab(message.testo.split('|')[0]);
                  } else if (rasterImageFormats.some(end => message.testo.split('|')[1].toLowerCase().endsWith(end))) {
                    fetchImagePreview(message.testo.split('|')[0]);
                  }
                }}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                {message.testo.split('|')[1]} {/* Nome del file visibile */}
              </Typography>
            </Box>
          )}

          {/* Ora Messaggi e Visto */}
          <Typography variant="caption" sx={{ display: 'block', mt: 0.3, opacity: 0.7, fontFamily: 'system-ui', fontSize: '11px' }}>
            {new Date(Number(message.timestamp)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
            {isOwn && role === 'operator' && message.letto && (
              <span style={{ marginLeft: '5px', fontStyle: 'italic' }}>visto</span>
            )}
          </Typography>

          {isSelected && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                right: (isOwn || forceAlignToRight) ? '100%' : '-35px',
                display: 'flex',
                flexDirection: 'column', // Dispone le icone una sotto l'altra
                alignItems: 'center',
                gap: '0px', // Distanza verticale tra le icone
              }}
            >
              {/* Icona Copia Messaggio */}
              <IconButton
                color="primary"
                size="small"
                onClick={() => navigator.clipboard.writeText(message.testo)}
              >
                <ContentCopyIcon sx={{ fontSize: '14px' }} />
              </IconButton>

              {/* Icona Quota Messaggi */}
              <IconButton
                color="primary"
                size="small"
                onClick={handleQuoteMessage}
              >
                <ShortcutIcon sx={{ fontSize: '14px' }} />
              </IconButton>

              {/* Icona Download */}
              {message.tipo === 'file' && (
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => {
                    role === 'client'
                      ? utils.cliDownload(message.testo.split('|')[0])
                      : utils.opDownload(message.testo.split('|')[0]);
                  }}
                >
                  <GetAppIcon sx={{ fontSize: '14px' }} />
                </IconButton>
              )}

              {/* Icona Elimina Messaggi */}
              {role === 'operator' && isOwn && message.testo != 'Messaggio Eliminato' && (
                <IconButton
                  color="primary"
                  size="small"
                  onClick={handleDeleteMessage} // Open the delete dialog
                >
                  <DeleteIcon sx={{ fontSize: '14px' }} />
                </IconButton>
              )}

              {/* Icona Info Messaggi */}
              {message.letture && <IconButton
                color="primary"
                size="small"
                onClick={() => handleInfoMessage(message.letture)} // Open the info dialog
              >
                <InfoIcon sx={{ fontSize: '14px' }} />
              </IconButton>}

              {/* Icona Download Immagine */}
              {message.tipo === 'image' && (
                <IconButton
                  color="primary"
                  size="small"
                  href={`data:image/jpeg;base64,${message.testo}`}
                  download="immagine.jpg"
                >
                  <GetAppIcon sx={{ fontSize: '14px' }} />
                </IconButton>
              )}
            </Box>
          )}
        </Paper>
      </Box>
    );
  };

  // Componente Principale------------------------------------------------
  return (
    <>
      {/* Icona della Chat Cliccabile per aprirla */}
      <Fab
        color="primary"
        size="small"
        onClick={() => openChat()}
        sx={{
          position: 'fixed',
          bottom: 65,
          right: 10,
        }}
      >
        <Badge badgeContent={unreadCount} color="error">
          <ChatIcon />
        </Badge>
      </Fab>

      {/* Corpo Chat */}
      <Drawer
        anchor={isMobile ? 'bottom' : 'right'}
        open={isOpen}
        onClose={() => closeChat()}
        PaperProps={{
          sx: {
            width: isMobile ? '100%' : isExpanded ? '100%' : 700, // Wider on desktop
            height: isMobile ? '100%' : '100vh',
            borderRadius: isMobile ? 0 : 4, // Rounded corners only on desktop
            // scrollbarColor: 'transparent transparent'
          }
        }}
      >
        {/* Barra di stato alta della chat */}
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <AppBar position="sticky" color="primary">
            <Toolbar>

              {/* icona per tornare indietro */}
              {(role != 'client' || null) && activeChat && (
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setActiveChat(null)}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}

              <Box sx={{ display: 'flex', alignItems: 'center', ml: 0, flexGrow: 1 }}>
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  {/* Avatar della chat attiva */}
                  <Avatar sx={{ mr: 2, width: 40, height: 40 }}
                    src={role === 'client' ? './avatar.png' : (activeChat ? `/api/avatar?id=${activeChat.id}` : `/api/avatar?id=${userId}`)} />
                  {role === 'operator' && !activeChat && (
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 10,
                        backgroundColor: 'white',
                        borderRadius: '100%',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                        width: '16px',
                        height: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <input
                        accept="image/*"
                        type="file"
                        id="avatar-upload"
                        style={{ display: 'none' }}
                        onChange={handleAvatarImageChange}
                      />
                      <label htmlFor="avatar-upload" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                        <AddIcon sx={{ fontSize: '16px', color: 'primary.main', cursor: 'pointer' }} />
                      </label>
                    </Box>
                  )}
                  {/* se la chat attiva e' un gruppo, puo' cambiare l'avatar */}
                  {role === 'operator' && activeChat && activeChat.id < 0 && (
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 10,
                        backgroundColor: 'white',
                        borderRadius: '100%',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                        width: '16px',
                        height: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <input
                        accept="image/*"
                        type="file"
                        id="avatar-upload"
                        style={{ display: 'none' }}
                        onChange={e => handleAvatarImageChange(e, activeChat.id)}
                      />
                      <label htmlFor="avatar-upload" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                        <AddIcon sx={{ fontSize: '16px', color: 'primary.main', cursor: 'pointer' }} />
                      </label>
                    </Box>
                  )}
                </Box>

                {/* Nome cliente barra di stato cliccabile per vedere le etichette */}
                <Typography
                  variant="h6"
                  onClick={role === 'operator' && activeChat && !activeChat.op ? () => setOpenLabelsDialog(true) : undefined}
                  style={{
                    cursor: role === 'operator' && activeChat && !activeChat.op ? 'pointer' : 'default',
                    fontSize: isMobile ? '0.8rem' : '1rem', // Riduci la dimensione del testo su dispositivi mobili
                    fontWeight: 'bold',
                  }}
                >
                  {activeChat ? activeChat.name : username}
                </Typography>
              </Box>

              {/* Icona Avatar per immagine profilo cliente */}
              {role === 'client' && (
                <Tooltip title="Carica la tua immagine profilo">
                  <Box sx={{ position: 'relative', display: 'inline-flex', ml: 0, mr: 0 }}>
                    <Avatar sx={{ width: 30, height: 30 }} src={`/api/avatar?id=${userId}`} />
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: -5,
                        backgroundColor: 'white',
                        borderRadius: '100%',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                        width: '16px',
                        height: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <input
                        accept="image/*"
                        type="file"
                        id="avatar-upload-client"
                        style={{ display: 'none' }}
                        onChange={handleAvatarImageChange}
                      />
                      <label htmlFor="avatar-upload-client" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                        <AddIcon sx={{ fontSize: '16px', color: 'primary.main', cursor: 'pointer' }} />
                      </label>
                    </Box>
                  </Box>
                </Tooltip>
              )}

              {/* Icona Cerca nella Chat */}
              {activeChat && (
                <IconButton
                  color="inherit"
                  onClick={() => setIsSearchVisible(!isSearchVisible)}
                >
                  <SearchIcon />
                </IconButton>
              )}

              {isSearchVisible && (
                <TextField
                  placeholder="Cerca"
                  variant="standard"
                  value={searchMessageTerm}
                  onChange={(e) => setSearchMessageTerm(e.target.value)}
                  size="small"
                  sx={{
                    ml: 1,
                    width: { xs: '100%', sm: '300px' }, // Dimensione fissa su desktop e full width su mobile
                    flexGrow: { xs: 1, sm: 0 }, // Flex grow solo su mobile
                    '& .MuiInputBase-input': {
                      color: 'white', // Colore del testo bianco
                    },
                  }}
                  InputProps={{
                    sx: {
                      border: 'none',
                      '& .MuiOutlinedInput-root': {
                        border: 'none',
                        borderRadius: '4px',
                      },
                    },
                  }}
                />
              )}

              {/* Icona per contrassegnare tutti i messaggi come letti */}
              {!activeChat && ( // Mostra solo se non c'è una chat attiva
                <Tooltip title="Contrassegna tutti i messaggi come letti">
                  <IconButton
                    color="inherit"
                    onClick={handleMarkAllAsRead}
                    sx={{ display: role === 'operator' ? 'inline-flex' : 'none' }} // Mostra solo per gli operatori
                  >
                    <CheckIcon />
                  </IconButton>
                </Tooltip>
              )}

              {/* icone per espandere e chiudere la chat */}
              <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                <IconButton color="inherit" onClick={() => setIsExpanded(!isExpanded)}>
                  {isExpanded ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </IconButton>
              </Box>
              <IconButton color="inherit" onClick={() => closeChat()}>
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          {/* Only the contact list will have the search box */}
          {activeChat === null ? (
            <Box sx={{ p: 2, }}>
              {role === 'operator' && (
                <TextField
                  placeholder="Cerca"
                  variant="outlined"
                  onChange={handleSearch}
                  fullWidth
                  InputProps={{
                    sx: {
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderRadius: '16px', // Arrotonda gli angoli del contorno dell'input
                      },
                    },
                  }}
                />
              )}

              <List sx={{ overflow: 'auto', flexGrow: 1 }}>

                {/* Tabs contatti | Tutti - Operatori - Clienti */}
                <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth">
                  <Tab label="Tutti" />
                  <Tab label="Operatori" />
                  <Tab label="Clienti" />
                </Tabs>

                {tabIndex === 0 && (
                  <List sx={{ overflow: 'auto', flexGrow: 1 }}>
                    {filteredClients.map((client) => (
                      <ListItem
                        key={client.id}
                        onClick={() => handleOpenChat(client)}
                        sx={{
                          '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                          }
                        }}
                      >
                        <ListItemAvatar>
                          {client.op ?
                            <Avatar sx={{ width: 40, height: 40 }} src={`/api/avatar?id=${client.id}`} /> :
                            <Avatar sx={{ width: 40, height: 40 }} />
                          }
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              {client.name}
                              {client.unreadCount > 0 && (
                                <Badge
                                  badgeContent={client.unreadCount}
                                  color="error"
                                  sx={{ ml: 2 }} // Add spacing between name and badge
                                >
                                  <span></span>
                                </Badge>
                              )}
                            </Box>
                          }
                          secondary={<Box
                            component="span"
                            sx={{
                              display: 'block',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: { xs: '220px', sm: '520px' },
                            }}
                          >
                            {client.lastMessage}
                          </Box>}
                        />
                        {role === 'operator' && <HandleDot client={client} clients={clients} setClients={setClients} />}
                      </ListItem>
                    ))}
                  </List>
                )}

                {tabIndex === 1 && (
                  <List sx={{ overflow: 'auto', flexGrow: 1 }}>
                    {filteredClients.filter(client => client.op).map((client) => (
                      <ListItem
                        key={client.id}
                        onClick={() => handleOpenChat(client)}
                        sx={{
                          '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                          }
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar sx={{ width: 40, height: 40 }} src={`/api/avatar?id=${client.id}`} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              {client.name}
                              {client.unreadCount > 0 && (
                                <Badge
                                  badgeContent={client.unreadCount}
                                  color="error"
                                  sx={{ ml: 2 }} // Add spacing between name and badge
                                >
                                  <span></span>
                                </Badge>
                              )}
                            </Box>
                          }
                          secondary={<Box
                            component="span"
                            sx={{
                              display: 'block',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: { xs: '220px', sm: '520px' },
                            }}
                          >
                            {client.lastMessage}
                          </Box>}
                        />
                        {role === 'operator' && <HandleDot client={client} clients={clients} setClients={setClients} />}
                      </ListItem>
                    ))}
                  </List>
                )}

                {tabIndex === 2 && (
                  <List sx={{ overflow: 'auto', flexGrow: 1 }}>
                    {filteredClients.filter(client => !client.op).map((client) => (
                      <ListItem
                        key={client.id}
                        onClick={() => handleOpenChat(client)}
                        sx={{
                          '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                          }
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar sx={{ width: 40, height: 40 }} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              {client.name}
                              {client.unreadCount > 0 && (
                                <Badge
                                  badgeContent={client.unreadCount}
                                  color="error"
                                  sx={{ ml: 2 }} // Add spacing between name and badge
                                >
                                  <span></span>
                                </Badge>
                              )}
                            </Box>
                          }
                          secondary={<Box
                            component="span"
                            sx={{
                              display: 'block',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: { xs: '220px', sm: '520px' },
                            }}
                          >
                            {client.lastMessage}
                          </Box>}
                        />
                        {role === 'operator' && <HandleDot client={client} clients={clients} setClients={setClients} />}
                      </ListItem>
                    ))}
                  </List>
                )}

                {/* Parte per i Contatti trovati */}
                {role === 'operator' && filteredClients.length == 0 &&
                  <Typography variant="body2" sx={{ textAlign: 'center', color: theme.palette.text.secondary }}>
                    Nessun contatto trovato
                  </Typography>}
              </List>
            </Box>
          ) : (
            <>

              {/* Corpo della chat tra barra e campo invio messaggi*/}
              <Box
                ref={messagesContainerRef}
                sx={{
                  flexGrow: 1,
                  overflow: 'auto',
                  p: 2,
                  backgroundColor: isDragging ? 'rgba(0, 0, 0, 0.15)' : theme.palette.grey[210],
                  position: 'relative',
                  overflowX: 'hidden',
                }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                {/* Pulsante per caricare messaggi precedenti */}
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                  <Button variant="outlined" size="small" onClick={() => { setIsLoading(true); fetchMessages(true) }}>
                    Carica Messaggi Precedenti
                  </Button>
                </Box>

                {/* Messaggi raggruppati per data */}
                {Object.keys(groupedMessages).map((date) => (
                  <React.Fragment key={date}>
                    <Typography
                      variant="caption"
                      sx={{
                        display: 'block',
                        textAlign: 'center',
                        margin: '16px 0',
                        fontWeight: 'bold',
                        color: theme.palette.text.secondary,
                      }}
                    >
                      {date}
                    </Typography>
                    {groupedMessages[date].map((msg) => (
                      <MessageBubble key={msg.index} message={msg} />
                    ))}
                  </React.Fragment>
                ))}
                <div ref={messagesEndRef} />
              </Box>

              {/* Paper Campo Input e Button Invio Messaggi Audio File ecc */}
              <Paper
                elevation={3}
                sx={{
                  p: 1,
                  backgroundColor: theme.palette.background.paper,
                }}
              >
                <Box sx={{ display: 'flex', gap: 0, alignItems: 'center' }}>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                  />

                  {/* Icona Emoji */}
                  <IconButton
                    color="primary"
                    disabled={isLoading}
                    onClick={() => setShowEmojiPicker((prev) => !prev)}
                  >
                    <SentimentVerySatisfiedIcon />
                  </IconButton>

                  {/* Icona Allega File */}
                  <IconButton
                    color="primary"
                    disabled={isLoading}
                    onClick={() => fileInputRef.current?.click()}
                  >
                    <AttachFileIcon />
                  </IconButton>

                  {/* Icona Fotocamera */}
                  <IconButton
                    color="primary"
                    disabled={isLoading}
                    onClick={() => document.getElementById('image-upload').click()}
                  >
                    <PhotoCameraIcon />
                  </IconButton>
                  <input
                    type="file"
                    id="image-upload"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        handleSendImage(e.target.files[0]);
                      }
                    }}
                  />

                  <Box sx={{ position: 'relative', width: '100%' }}>
                    {/* Nuvoletta per il messaggio quotato */}
                    {quotedMessage && (
                      <Box
                        sx={{
                          position: 'absolute',
                          bottom: '100%', // Ancorata subito sopra il campo input
                          marginBottom: '8px', // Distanza tra la nuvoletta e il campo input
                          left: '50%', // Centra la nuvoletta
                          transform: 'translateX(-50%)', // Centra la nuvoletta
                          backgroundColor: '#4076bf', // Colore del bubble scuro
                          color: 'white', // Colore del testo
                          borderRadius: 5,
                          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                          padding: '8px',
                          zIndex: 1000,
                          display: 'flex',
                          alignItems: 'center',
                          width: 'calc(100% - 16px)', // Larghezza della nuvoletta uguale a quella del campo di input
                          whiteSpace: 'pre-wrap',
                          wordBreak: 'break-word',
                        }}
                      >
                        <IconButton
                          size="small"
                          sx={{ marginLeft: '-8px', marginRight: '8px' }}
                          onClick={() => setQuotedMessage(null)}
                        >
                          <CloseIcon sx={{ fontSize: '16px', color: 'white' }} />
                        </IconButton>

                        <Typography variant="body2" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                          {/* Nome della citazione */}
                          <Typography variant="caption" sx={{ fontWeight: 'bold', color: '#ADD8E6' }}>
                            {role === 'operator'
                              ? quotedMessage.nome // Se operatore, mostra sempre il nome dell'autore
                              : (quotedMessage.utente_id === userId // Se non operatore, ma il messaggio è dell'utente
                                ? username // Mostra il proprio nome
                                : "Studio Romeo" // Altrimenti mostra "Studio Romeo"
                              )
                            }
                          </Typography>
                          <Typography variant="body2">
                            {quotedMessage.testo.length > (isMobile ? 20 : 100)
                              ? quotedMessage.testo.substring(0, isMobile ? 20 : 100) + '...'
                              : quotedMessage.testo}
                          </Typography>
                        </Typography>

                      </Box>
                    )}

                    {/* Campo input dove scrivi il messaggio */}
                    <TextField
                      fullWidth
                      size="small"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Scrivi..."
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && e.shiftKey) { // Vai a capo con Shift + Enter
                          e.preventDefault();
                          setTimeout(() => {
                            setMessage((prevMessage) => prevMessage + '\n');
                          }, 50);
                        } else if (e.key === 'Enter') { // Invio messaggio con Enter
                          e.preventDefault();
                          handleSendMessage();
                        }
                      }}
                      onPaste={handlePaste}
                      variant="outlined"
                      multiline
                      minRows={1}
                      maxRows={4}
                      InputProps={{
                        style: {
                          overflow: 'hidden',
                          overflowWrap: 'break-word',
                          resize: 'none',
                          borderRadius: '16px',
                        },
                      }}
                    />
                  </Box>

                  {/* Icona Registra Messaggi */}
                  <IconButton
                    color={isRecording ? 'error' : 'primary'}
                    disabled={isLoading}
                    onClick={handleVoiceRecord}
                  >
                    {isRecording ? <StopIcon /> : <MicIcon />}
                  </IconButton>

                  {/* Icona Invio Messaggi */}
                  <IconButton
                    color="primary"
                    onClick={handleSendMessage}
                    disabled={!message.trim()}
                  >
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : <SendIcon />}
                  </IconButton>

                  {/* Icona Template Messaggi */}
                  {role === 'operator' && (
                    <Tooltip title="Messaggi Template">
                      <IconButton
                        color="primary"
                        onClick={() => setOpenTemplateDialog(true)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>

                {/* Selettore di Emoji */}
                {showEmojiPicker && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: '50px', // Posiziona il selettore sopra il campo di input
                      left: '10px', // Posizionamento relativo all'icona emoji
                      zIndex: 1000, // Assicurati che il selettore sia visibile sopra altri elementi
                      backgroundColor: 'white', // Imposta uno sfondo per visibilità
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Aggiungi un'ombra per stile
                      borderRadius: '8px',
                    }}
                  >
                    <EmojiPicker
                      onEmojiClick={(emoji) => {
                        setMessage((prevMessage) => prevMessage + emoji.emoji);
                        setShowEmojiPicker(false);
                      }}
                    />
                  </Box>
                )}
              </Paper>
            </>
          )}
        </Box>

        {/* Dialog Conferma Elimina Messaggi */}
        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
        >
          <DialogTitle>Conferma Eliminazione</DialogTitle>
          <DialogContent>
            <Typography>
              Sei sicuro di voler eliminare questo messaggio?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">
              Annulla
            </Button>
            <Button
              onClick={() => handleDeleteMessageConfirm()}
              color="primary"
            >
              Elimina
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog Anteprima Immagini */}
        <Dialog
          open={!!imagePreview}
          onClose={handleCloseImagePreview}
          fullWidth
          maxWidth="md"
        >
          <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
            <TransformWrapper
              initialScale={1} // Imposta lo zoom iniziale al 100%
              minScale={0.5} // Imposta lo zoom minimo al 50%
              maxScale={3} // Imposta lo zoom massimo al 300%
              wheel={{ step: 0.1 }} // Imposta l'incremento dello zoom con la rotellina del mouse
              panning={{ disabled: false }} // Abilita il panning
              pinch={{ disabled: true }} // Disabilita il pinch zoom
            >
              <TransformComponent>
                <img
                  src={imagePreview}
                  alt="Anteprima immagine"
                  style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain', margin: 'auto' }}
                />
              </TransformComponent>
            </TransformWrapper>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseImagePreview} color="primary">
              Chiudi
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog Messaggi Template */}
        <Dialog
          open={openTemplateDialog}
          onClose={() => setOpenTemplateDialog(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Messaggi Template</DialogTitle>
          <DialogContent>
            <TextField
              label="Titolo"
              value={newTemplate.title}
              onChange={(e) => setNewTemplate({ ...newTemplate, title: e.target.value })}
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Testo"
              value={newTemplate.text}
              onChange={(e) => setNewTemplate({ ...newTemplate, text: e.target.value })}
              fullWidth
              variant="outlined"
              margin="normal"
              multiline
              rows={5}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddTemplate}
              disabled={!newTemplate.title.trim() || !newTemplate.text.trim()}
            >
              Aggiungi
            </Button>
            <List>
              {templates.map((template, index) => (
                <React.Fragment key={index}>
                  <ListItem
                    key={index}
                    button
                    onClick={() => handleSelectTemplate(template.text)}
                  >
                    <ListItemText primary={truncateText(template.title, 40)} /> {/* Tronca il testo a 40 caratteri */}
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteTemplate(index);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                  {index < templates.length - 1 && <Divider />} {/* Aggiunge un Divider tra gli elementi */}
                </React.Fragment>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenTemplateDialog(false)} color="primary">
              Chiudi
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog Info data ora e visto messaggi */}
        <Dialog
          open={openInfoDialog}
          onClose={() => setOpenInfoDialog(false)}
        >
          <DialogTitle>Informazioni Messaggio</DialogTitle>
          <DialogContent>
            {messageInfo && (
              <>
                <Typography>Visto da:</Typography>
                <List>
                  {Object.keys(messageInfo)
                    .filter(id => parseInt(id) !== userId) // Esclude il mittente
                    .map(id => (
                      <ListItem key={id}>
                        <ListItemText primary={operatorMap[id] || "Cliente"} secondary={new Date(messageInfo[id]).toLocaleString()} />
                      </ListItem>
                    ))}
                </List>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenInfoDialog(false)} color="primary">
              Chiudi
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog Info etichette clienti */}
        <Dialog
          open={openLabelsDialog}
          onClose={() => setOpenLabelsDialog(false)}
        >
          <DialogTitle>Etichette del Cliente</DialogTitle>
          <DialogContent>
            {clientLabels.length > 0 ? (
              clientLabels.map((label, index) => (
                <Typography key={index} variant="body1">
                  {label}
                </Typography>
              ))
            ) : (
              <Typography variant="body1">Nessuna etichetta disponibile</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenLabelsDialog(false)} color="primary">
              Chiudi
            </Button>
          </DialogActions>
        </Dialog>

      </Drawer>
    </>
  );
};

export default Chat;
