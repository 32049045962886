import { useEffect } from 'react';
import axios from 'axios';
import utils from '../utils';

const PK = 'BKsVbnN5I01IA9rJor0NmfrRVnrRMQ3ehVwprVwflmyuurtD6zxYHZlupmP7WRpWXC8MYUBEYcseKyaQ-nf6cJM';

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}


const subscribeUser = async () => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
        try {
            const reg = await navigator.serviceWorker.ready;
            const subscription = await reg.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(PK)
            });

            console.log('Iscritto alle notifiche push:', JSON.stringify(subscription));
            // Invia l'iscrizione al tuo backend per salvarla

            const userType = sessionStorage.getItem("usertype") || localStorage.getItem("usertype");
            if (userType == 'dip') {
                await axios.post('/api/salvaiscrizionepushdip', subscription, {
                    headers: utils.getDipendenteAuthHeaders()
                });
            } else {
                await axios.post('/api/salvaiscrizionepush', subscription, {
                    headers: utils.getAuthHeaders()
                });
            }
        } catch (error) {
            console.error('Errore nell’iscrizione:', error);
        }
    }
};

// Chiamalo una volta, ad esempio dopo che l’utente ha accettato
const NotificationPermissionRequest = () => {
    useEffect(() => {
        if ('Notification' in window) {
            const permission = Notification.permission;

            if (permission === 'granted') {
                console.log('Le notifiche sono abilitate.');
            } else if (permission === 'denied') {
                console.log('Le notifiche sono disabilitate.');
            } else {
                console.log('Le notifiche non sono state ancora richieste.');
            }
        }

        if ('Notification' in window && Notification.permission === 'default') {
            Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    subscribeUser();
                    console.log('Notifiche abilitate');
                } else {
                    console.log('Notifiche disabilitate');
                }
            });
        }
    }, []);

    return null; // Non renderizza nulla
};

export default NotificationPermissionRequest;
