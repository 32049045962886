import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Nav.css";
import { Typography } from "@mui/material";
import utils from "../../utils";
import axios from "axios";
import logo from "../../logo.svg";
import { FiCalendar, FiFileText, FiBook, FiUser, FiLogIn, FiLogOut, FiBookOpen, FiClock } from "react-icons/fi";

const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLinkClick = () => {
    setMenuOpen(false); // Chiude il menu a tendina quando un link viene cliccato
  };

  // Chiude il menu a tendina quando clicchi fuori dal menu
  const handleClickOutside = (event) => {
    const navLinks = document.querySelector('.nav-links');
    const menuIcon = document.querySelector('.menu-icon');
    if (navLinks && !navLinks.contains(event.target) && !menuIcon.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const userType = sessionStorage.getItem("usertype") || localStorage.getItem("usertype");

  const logout = async () => {
    try {
      if (userType == "dip") {
        sessionStorage.removeItem("usertype");
        sessionStorage.removeItem("dipendente");
        localStorage.removeItem("usertype");
        localStorage.removeItem("dipendente");
        window.location.href = "/";
      } else {
        const res = await axios.post(
          "/api/logout",
          {},
          { headers: utils.getAuthHeaders(), validateStatus: () => true }
        );
        if (res.status === 200) {
          const { success, error } = res.data;
          if (success) {
            sessionStorage.removeItem("jwt");
            sessionStorage.removeItem("usertype");
            localStorage.removeItem("jwt");
            localStorage.removeItem("usertype");
            window.location.href = "/";
            return;
          } else if (error) {
            console.log(error);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <nav className="navbar" id="navbar-item">
      <div className="brand">
        <Link to="/" onClick={handleLinkClick}>
          <img src={logo} alt="Logo" />
        </Link>{" "}
      </div>
      <div className={`nav-links ${menuOpen ? "open" : ""}`}>
        {userType === "op" && (
          <Link to="/formazione" onClick={handleLinkClick}>
            <FiBookOpen /> Formazione
          </Link>
        )}
        <Link to="/scadenze" onClick={handleLinkClick}>
          <FiCalendar /> Scadenze
        </Link>
        <Link to="/moduli" onClick={handleLinkClick}>
          <FiFileText /> Moduli
        </Link>
        <Link to="/blog" onClick={handleLinkClick}>
          <FiBook /> Blog
        </Link>
        {userType === "op" && (
          <Link to={"/op"} onClick={handleLinkClick}>
            <FiUser /> Area Op
          </Link>
        )}
        {userType === "user" && (
          <Link to={"/cli"} onClick={handleLinkClick}>
            <FiUser /> Area Clienti
          </Link>
        )}
        {userType === "dip" && (
          <Link to={"/dip"} onClick={handleLinkClick}>
            <FiUser /> Area Dipendenti
          </Link>
        )}
        {userType ? (
          <Link onClick={logout}>
            <FiLogOut /> Logout
          </Link>
        ) : (
          <>
            <Link to="/logindipendenti" onClick={handleLinkClick}>
              <FiClock git /> Timbratore
            </Link>
            <Link to="/login" onClick={handleLinkClick}>
              <FiLogIn /> Login
            </Link>
          </>
        )}

      </div>
      <div className="menu-icon" onClick={handleMenuClick}>
        <div className="hamburger-icon">
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
