import axios from 'axios';

const getAuthHeaders = () => {
  const jwt = sessionStorage.getItem('jwt') || localStorage.getItem('jwt') || '';
  return { 'Authorization': `Bearer ${jwt}` };
}

const getDipendenteAuthHeaders = () => {
  const dip = sessionStorage.getItem('dipendente') || localStorage.getItem('dipendente') || '';
  return { 'dipendente': dip };
}

const formatData = (ts) => {
  const date = new Date(ts);
  return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear().toString().substring(2)}`
}

function min2hours(min) {
  const hours = Math.floor(min / 60);
  return `${hours}:${(min - 60 * hours).toString().padStart(2, '0')}`;
}

function revDate(dateStr) {
  if (!dateStr) {
    return dateStr;
  }
  return dateStr.substring(8, 10) + '/' + dateStr.substring(5, 7) + '/' + dateStr.substring(0, 4);
}

const opDownload = async (docId) => {
  const response = await axios.get(`/api/scarica?id=${docId}`, {
    headers: getAuthHeaders(),
    responseType: "blob",
  });

  const contentDisposition = response.headers["content-disposition"];
  const filename = contentDisposition
    ? contentDisposition.split(";")[1].split("filename=")[1].trim()
    : "documento";

  const fileBlob = new Blob([response.data]);
  const fileUrl = URL.createObjectURL(fileBlob);

  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = filename;
  link.click();
  URL.revokeObjectURL(fileUrl);
};

const cliDownload = async (docId) => {
  const response = await axios.get(`/api/scaricadoccliente?id=${docId}`, {
    headers: getAuthHeaders(),
    responseType: "blob",
  });

  const contentDisposition = response.headers["content-disposition"];
  const filename = contentDisposition
    ? contentDisposition.split(";")[1].split("filename=")[1].trim()
    : "documento";

  const fileBlob = new Blob([response.data]);
  const fileUrl = URL.createObjectURL(fileBlob);

  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = filename;
  link.click();
  URL.revokeObjectURL(fileUrl);
};

const opOpenPdfInNewTab = async (docId) => {
  const response = await axios.get(`/api/scarica?id=${docId}`, {
    headers: getAuthHeaders(),
    responseType: "blob",
  });

  const fileBlob = new Blob([response.data], { type: "application/pdf" });
  const fileUrl = URL.createObjectURL(fileBlob);

  window.open(fileUrl, "_blank");

  URL.revokeObjectURL(fileUrl);
};

const cliOpenPdfInNewTab = async (docId) => {
  const response = await axios.get(`/api/scaricadoccliente?id=${docId}`, {
    headers: getAuthHeaders(),
    responseType: "blob",
  });

  const fileBlob = new Blob([response.data], { type: "application/pdf" });
  const fileUrl = URL.createObjectURL(fileBlob);

  window.open(fileUrl, "_blank");

  URL.revokeObjectURL(fileUrl);
};

export default {
  getAuthHeaders,
  getDipendenteAuthHeaders,
  formatData,
  min2hours,
  revDate,
  opDownload,
  cliDownload,
  opOpenPdfInNewTab,
  cliOpenPdfInNewTab,
}
