import React, { useState, useEffect } from "react";
import {
  Box, Typography, Grid, Paper, FormControl, Select, MenuItem, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Button, Alert, Snackbar
} from "@mui/material";
import { PieChart, Pie, Cell, LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Bar, BarChart } from "recharts";
import axios from "axios";
import utils from "../../utils";

const Logs = () => {
  const anni = [];
  let anno = 2025;
  while (anno <= new Date().getFullYear()) {
    anni.push(anno);
    anno += 1;
  }

  const [selectedMonth, setSelectedMonth] = useState(-1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogList, setDialogList] = useState([]);
  const [error, setError] = useState("");
  const [ragioniSociali, setRagioniSociali] = useState({});
  const [notifications, setNotifications] = useState({ accettate: [], nonAccettate: [] });
  const [attivita, setAttivita] = useState({
    giorni: [], // { g: 1, u: ['az1', 'az2'] }
    utenti: [], // { u: 'az1', g: [1, 5, 6] }
  });

  useEffect(() => {
    fetchRagioniSociali();
    fetchNotifications();
  }, []);

  useEffect(() => {
    fetchLogs();
  }, [selectedMonth, selectedYear]);

  const fetchRagioniSociali = async () => {
    try {
      const res = await axios.get(`/api/aziendeeop`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        const dizionario = {};
        for (const az of res.data.aziende) {
          dizionario[az.id] = az.nome;
        }
        setRagioniSociali(dizionario);
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
  }

  const fetchNotifications = async () => {
    try {
      const res = await axios.get(`/api/notificheinfo`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setNotifications(res.data.notifiche);
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
  }

  const fetchLogs = async () => {
    try {
      const res = await axios.get(`/api/attivitautenti?m=${selectedMonth}&y=${selectedYear}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAttivita(res.data.attivita);
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
  };

  const userActivityData = attivita.utenti.map(utente => {
    return { user: ragioniSociali[utente.u], activeDays: utente.g.length };
  });

  const pieData = [
    { name: "Almeno un dispositivo", value: notifications.accettate.length, aziende: notifications.accettate },
    { name: "Nessun dispositivo", value: notifications.nonAccettate.length, aziende: notifications.nonAccettate },
  ];

  const dailyLogins = attivita.giorni.map(day => {
    return { day: day.g, count: day.u.length };
  });

  const COLORS = [
    "#6495ED", // CornflowerBlue Accettate
    "#FF6347", // Tomato Non Accettate
  ];

  const handlePieClick = (data, index) => {
    setDialogTitle("Clienti");
    console.log(data)
    setDialogList(data.aziende);
    setOpenDialog(true);
  };

  const handleLineClick = (data, index) => {
    const users = attivita.giorni.filter(day => day.g == data.activeLabel)[0].u;
    setDialogTitle("Utenti Loggati");
    setDialogList(users.map(az => ragioniSociali[az]));
    setOpenDialog(true);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        Log Attività Utenti
      </Typography>

      <Grid container spacing={4}>
        {/* Month and Year Selectors */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Select
              value={selectedMonth}
              onChange={handleMonthChange}
              displayEmpty
            >
              <MenuItem key={-1} value={-1}>
                {"Ultimi 30 giorni"}
              </MenuItem>
              {[...Array(12).keys()].map((month) => (
                <MenuItem key={month} value={month}>
                  {new Date(selectedYear, month).toLocaleString('default', { month: 'long' }).charAt(0).toUpperCase() + new Date(selectedYear, month).toLocaleString('default', { month: 'long' }).slice(1)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Select
              value={selectedYear}
              onChange={handleYearChange}
              displayEmpty
            >
              {anni.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Charts Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Consensi Notifiche
          </Typography>
          <Paper sx={{ padding: 2 }}>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={pieData}
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  innerRadius={30}
                  paddingAngle={5}
                  cornerRadius={10}
                  dataKey="value"
                  label
                  onClick={handlePieClick}
                  isAnimationActive={true}
                  style={{
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    borderRadius: '50%',
                  }}
                >
                  {pieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend
                  iconType="circle"
                  formatter={(value, entry) => (
                    <span style={{ fontStyle: 'italic' }}>{entry.payload.name}</span>
                  )}
                />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Loggati Giornalieri
          </Typography>
          <Paper sx={{ padding: 2 }}>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={dailyLogins}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                onClick={handleLineClick}
              >
                <XAxis dataKey="day" />
                <YAxis />
                <Tooltip
                  formatter={(value, name, props) => {
                    const day = props.payload.day;
                    const count = props.payload.count;
                    let date;
                    if (selectedMonth == -1) {
                      date = new Date();
                      if (Number(day) > new Date().getDate()) {
                        date.setMonth(date.getMonth() - 1);
                      }
                      date.setDate(day);
                    } else {
                      date = new Date(selectedYear, selectedMonth, day);
                    }
                    const dayName = date.toLocaleString('default', { weekday: 'short' }).charAt(0).toUpperCase() + date.toLocaleString('default', { weekday: 'short' }).slice(1);
                    return [`${count}`, `${dayName}`];
                  }}
                  labelFormatter={(label) => {
                    return ''; // Rimuove il numero del giorno dal tooltip
                  }}
                />
                <Legend
                  iconType="circle"
                  formatter={(value, entry) => (
                    <span style={{ fontStyle: 'italic' }}>
                      {selectedMonth == -1 ? 'Ultimi 30 giorni' : new Date(selectedYear, selectedMonth).toLocaleString('default', { month: 'long' }).charAt(0).toUpperCase() + new Date(selectedYear, selectedMonth).toLocaleString('default', { month: 'long' }).slice(1)}
                    </span>
                  )}
                />
                <Line type="monotone" dataKey="count" stroke="#1E90FF" strokeWidth={3} onClick={handleLineClick} style={{
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                }} />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Top 10 Utenti Attivi
          </Typography>
          <Paper sx={{ padding: 2 }}>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={userActivityData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis dataKey="user" />
                <YAxis />
                <Tooltip
                  formatter={(value, name, props) => {
                    const activeDays = props.payload.activeDays;
                    return [`Giorni Attivi: ${activeDays}`];
                  }}
                />
                <Legend
                  iconType="circle"
                  formatter={(value, entry) => (
                    <span style={{ fontStyle: 'italic' }}>
                      {selectedMonth == -1 ? 'Ultimi 30 giorni' : new Date(selectedYear, selectedMonth).toLocaleString('default', { month: 'long' }).charAt(0).toUpperCase() + new Date(selectedYear, selectedMonth).toLocaleString('default', { month: 'long' }).slice(1)}
                    </span>
                  )}
                />
                <Bar dataKey="activeDays" fill="#008B8B" barSize={30} style={{
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                  borderRadius: '4px',
                }} />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>

      {error && (
        <Snackbar open={true} autoHideDuration={6000} onClose={() => setError(null)}>
          <Alert onClose={() => setError(null)} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}

      {/* Dialog Lista Clienti */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            <ol style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
              {dialogList.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ol>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Logs;
