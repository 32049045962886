import React, { useState, useEffect } from "react";
import {
  TextField, Button, Input, Box, Container, Typography, Paper, CircularProgress, FormControl,
  MenuItem, InputLabel, Select, Autocomplete,
}
  from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/it';
import JSZip from 'jszip';
import axios from "axios";
import utils from "../../utils";
import ContrSelectDipendente from './ContrSelectDipendente';

const ContrDistaccoEst = ({ op }) => {
  const [formValues, setFormValues] = useState({
    nome: "",
    cognome: "",
    dataNascita: "",
    codiceFiscale: "",
    dataInizioDistacco: null,
    dataFineDistacco: null,
    aziendaOrigine: "",
    aziendaDestinazione: "",
    motivoDistacco: "",
    note: "",
    documenti: null,
    moduli: null,
  });

  const [statusMessage, setStatusMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [aziende, setAziende] = useState([]);
  const [aziendaId, setAziendaId] = useState(0);
  const [isMaster, setIsMaster] = useState(false);
  const [operatori, setOperatori] = useState([{ id: 0, nome: 'Caricamento...' }]);

  // Effetto per il caricamento dei dati iniziali
  useEffect(() => {
    loadData();
    if (op) {
      loadAziendeEOp();
    }
  }, []);

  // Funzione per il caricamento dei dati iniziali
  const loadData = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });

      if (res.status === 200) {
        const { success, error, me } = res.data;
        if (success && op && !me.op) {
          window.location.href = "/cli";
          return;
        } if (success) {
          setIsMaster(me.is_master);
          if (me.is_master) {
            loadSottoAziende();
          }
          setLoading(false);
          return; // Rimani sulla pagina
        } else if (error) {
          setStatusMessage(error);
        }
      }
    } catch (error) {
      setStatusMessage("Si è verificato un errore imprevisto sul nostro server.");
    }

    setLoading(false);

    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login";
    }
  };

  const loadAziendeEOp = async () => {
    try {
      const res = await axios.get(`/api/aziendeeop`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAziende(res.data.aziende);
        setOperatori(res.data.operatori);
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
  }

  const loadSottoAziende = async () => {
    try {
      const res = await axios.get(`/api/sottoaziende`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAziende(res.data.aziende);
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
  }

  // Funzione per la gestione delle selezioni
  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleDateChange = (name, newValue) => {
    if (!newValue) {
      setFormValues({
        ...formValues,
        [name]: null,
      });
      return;
    }
    if (newValue.$d.toString() == 'Invalid Date') {
      return;
    }
    const day = newValue.$D.toString().padStart(2, "0");
    const month = (newValue.$M + 1).toString().padStart(2, "0");
    const year = newValue.$y;
    const formattedDate = `${year}-${month}-${day}`;
    setFormValues({
      ...formValues,
      [name]: formattedDate,
    });
  };

  const handleMultiFileChange = (e, name) => {
    const files = e.target.files;

    if (files.length == 1) {
      setFormValues({
        ...formValues,
        [name]: { name: files[0].name, file: files[0] },
      });
      return;
    }

    const zip = new JSZip();

    // Aggiungi tutti i file al contenuto del file zip
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      zip.file(file.name, file);
    }

    // Crea un blob zip contenente tutti i file
    zip.generateAsync({ type: 'blob' }).then((zippedBlob) => {
      setFormValues({
        ...formValues,
        [name]: { name: `${name}.zip`, file: zippedBlob },
      });
    });
  }

  const handleSubmit = async (e) => {
    setStatusMessage("");
    e.preventDefault();


    if (!formValues.dataInizioDistacco || !formValues.dataFineDistacco) {
      setStatusMessage("Inserire data inizio e fine distacco");
      return;
    }

    const json = {
      tipo: "distacco_estero",
      nome: formValues.nome,
      cognome: formValues.cognome,
      cod_fiscale: formValues.codiceFiscale,
      data_nascita: formValues.dataNascita,
      data_inizio_distacco: formValues.dataInizioDistacco,
      data_fine_distacco: formValues.dataFineDistacco,
      azienda_origine: formValues.aziendaOrigine,
      azienda_destinazione: formValues.aziendaDestinazione,
      motivo_distacco: formValues.motivoDistacco,
      note: formValues.note,
      azienda: formValues.azienda,
      in_carico: formValues.inCarico,
    };

    const payload = new FormData();

    payload.append("doc", formValues.documenti.file);
    payload.append("mod", formValues.moduli.file);

    payload.append("data", JSON.stringify(json));

    try {
      setLoading(true);
      const res = await axios.post("/api/createticket", payload, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, error } = res.data;
      setLoading(false);
      if (success) {
        if (op) {
          window.location.href = "/success?backto=%2Fop%2Fcontrattualistica";
        } else {
          window.location.href = "/success";
        }
      } else {
        setStatusMessage(error);
        return;
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 413) {
        setStatusMessage(
          "I files sono troppo grandi. Il limite massimo è di 100 MB."
        );
      } else {
        setStatusMessage(
          "Si è verificato un errore imprevisto sul nostro server."
        );
      }
    }
  };

  return (
    <Container>
      <Typography variant="h4" align="center" mt={3}>
        Pratica di Distacco Estero
      </Typography>

      {/* Selettore Dipendente */}
      <ContrSelectDipendente setFormValues={setFormValues} aziendaId={aziendaId} />

      <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
        <form className="distacco-form" onSubmit={handleSubmit}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
            <Box
              display="grid"
              gap={2}
              borderRadius={5}
              border="1px solid #ccc"
              padding={3}
              marginX="auto"
              gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }}
            >
              <TextField
                label="Cognome"
                type="text"
                name="cognome"
                value={formValues.cognome}
                onChange={handleInputChange}
                required
              />
              <TextField
                label="Nome"
                type="text"
                name="nome"
                value={formValues.nome}
                onChange={handleInputChange}
                required
                title="Inserisci il tuo nome"
              />
              <TextField
                label="Data di Nascita"
                type="text"
                name="dataNascita"
                value={formValues.dataNascita}
                onChange={handleInputChange}
                required
              />
              <TextField
                label="Codice Fiscale"
                type="text"
                name="codiceFiscale"
                value={formValues.codiceFiscale}
                onChange={handleInputChange}
                required
              />
              <DatePicker
                label="Data Inizio Distacco *"
                format="DD/MM/YYYY"
                value={formValues.dataInizioDistacco}
                onChange={(newValue) =>
                  handleDateChange("dataInizioDistacco", newValue)
                }
                required
              />
              <DatePicker
                label="Data Fine Distacco *"
                format="DD/MM/YYYY"
                value={formValues.dataFineDistacco}
                onChange={(newValue) =>
                  handleDateChange("dataFineDistacco", newValue)
                }
                required
              />
              <TextField
                label="Azienda di Origine"
                type="text"
                name="aziendaOrigine"
                value={formValues.aziendaOrigine}
                onChange={handleInputChange}
                required
              />
              <TextField
                label="Azienda di Destinazione"
                type="text"
                name="aziendaDestinazione"
                value={formValues.aziendaDestinazione}
                onChange={handleInputChange}
                required
              />
              <TextField
                label="Motivo Distacco"
                type="text"
                name="motivoDistacco"
                value={formValues.motivoDistacco}
                onChange={handleInputChange}
                required
                multiline
                rows={5}
                style={{ marginBottom: "20px" }}
              />
              <TextField
                label="Note (nomi altri operai ecc)"
                type="text"
                name="note"
                value={formValues.note}
                onChange={handleInputChange}
                multiline
                rows={5}
              />

              <h2>Documenti e Moduli</h2>
              <div></div>

              <label htmlFor="documenti">
                Documenti Dipendente/i*
              </label>
              <input
                type="file"
                name="documenti"
                onChange={(e) => handleMultiFileChange(e, "documenti")}
                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                multiple
                required
              />
              <label htmlFor="moduli">
                Altri moduli*
              </label>
              <input
                type="file"
                name="moduli"
                onChange={(e) => handleMultiFileChange(e, "moduli")}
                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                multiple
                required
              />

              {op ? (<h2>Azienda e incaricato:</h2>) : null}
              {isMaster ? (<h2>Azienda:</h2>) : null}
              {(op || isMaster) ? (<Typography></Typography>) : null}
              {(op || isMaster) ?
                (
                  <FormControl>
                    <Autocomplete
                      id="azienda"
                      value={formValues.azienda}
                      onChange={(event, newValue) => {
                        setFormValues({
                          ...formValues,
                          azienda: newValue,
                        });
                        setAziendaId(newValue);
                      }}
                      options={aziende.map((az) => az.id)}
                      getOptionLabel={(id) => {
                        const a = aziende.filter(az => az.id == id)[0];
                        return `${a.nome} (${a.id})`;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Azienda"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                ) : null
              }
              {op ?
                (
                  <FormControl>
                    <InputLabel id="select-op-label">Operatore</InputLabel>
                    <Select
                      labelId="inCarico-label"
                      id="inCarico"
                      name="inCarico"
                      value={formValues.inCarico}
                      onChange={handleSelectChange}
                      required
                      label="Operatore"
                    >
                      {operatori.map((ope) => (
                        <MenuItem key={ope.id} value={ope.id}>{ope.nome}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : null
              }
              {isMaster ? (<Typography></Typography>) : null}
            </Box>
          </LocalizationProvider>

          <Box
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
            flexDirection="column"
            px={2}
          >
            {statusMessage && (
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  mt: 2,
                  flexGrow: 1,
                  backgroundColor: "#e57373",
                  borderColor: "#d32f2f",
                  color: "black",
                  width: "100%",
                }}
                role="alert"
              >
                {statusMessage}
              </Paper>
            )}

            <Typography textAlign="center" color="red" my={1}>
              *Allegare questi documenti, fronte e retro, se l'assunzione non è stata fatta da questo Studio
            </Typography>

            <Box
              justifyContent="center"
              alignItems="center"
              display="flex"
              flexDirection="column"
              px={2}
            >
              {loading ? (
                <CircularProgress sx={{ mt: 2 }} />
              ) : (
                <Button type="submit" variant="contained" sx={{ mt: 2, px: 8 }}>
                  Invia
                </Button>
              )}
            </Box>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default ContrDistaccoEst;