import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Grid, Paper, Box, Typography, Stack, CircularProgress, TextField, Autocomplete, LinearProgress } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import axios from "axios";
import utils from "../../utils";

const years = [2024, 2025, 2026];

const Fatturato = () => {
  const [selectedYear, setSelectedYear] = useState(years[0]);
  const [bars, setBars] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [aziende, setAziende] = useState([]);
  const [azienda, setAzienda] = useState(null);
  const [payments, setPayments] = useState({ completedPercentage: 0, daPagare: 0 });

  useEffect(() => {
    loadCharts(years[0]);
    loadAziende();
  }, []);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    loadCharts(event.target.value);
  };

  const loadCharts = async (year) => {
    setIsLoading(true);
    try {
      const res = await axios.get(`/api/fatturatostats?anno=` + year, {
        headers: utils.getAuthHeaders(),
      });
      const { success, bars } = res.data;
      if (success) {
        setBars(bars);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const loadAziende = async () => {
    try {
      const res = await axios.get(`/api/aziendeeop`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAziende(res.data.aziende);
      } else {
        console.error(error);
      }
    } catch (error) {
      console.log(error);
    }
    if (process.env.REACT_APP_ENV == "test") {
      setAziende([{ id: 1, nome: 'Azienda Test' }]);
    }
  }

  const loadPayments = async (companyId) => {
    setIsLoading(true);
    try {
      const res = await axios.get(`/api/barrapagamentiop?id=` + companyId, {
        headers: utils.getAuthHeaders(),
      });
      if (res.data.success) {
        const { barra } = res.data;
        const percentuale = Math.round(100 * barra.pagati / (barra.pagati + barra.daPagare));
        const daPagare = (barra.daPagare / 100).toFixed(2);
        setPayments({ completedPercentage: percentuale, daPagare });
      }
    } catch (error) {
      console.error('Error fetching payments:', error);
    }
    setIsLoading(false);
  };

  const handleCompanyChange = (value) => {
    setAzienda(value);
    if (value) {
      loadPayments(value);
    } else {
      setPayments({ completedPercentage: 0, daPagare: 0 });
    }
  };

  return (
    <Box p={4} height="100%" display="flex" flexDirection="column" alignItems="center">
      {/* Primo Box con il grafico */}
      <Paper elevation={3} style={{ width: '100%', maxWidth: 1200, padding: '20px' }}>
        <Box mb={4} textAlign="center">
          <Typography variant="h5" margin={0} align="center" fontWeight="bold" gutterBottom>
            Fatturato Studio Romeo
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Anno</InputLabel>
              <Select
                label="Anno"
                value={selectedYear}
                onChange={handleYearChange}
              >
                {years.map(year => (
                  <MenuItem key={year} value={year}>{year}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {isLoading ? (
              <Stack sx={{ my: 3 }} alignItems="center">
                <CircularProgress disableShrink />
              </Stack>
            ) : (
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  data={bars}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip
                    formatter={(value) => {
                      // Formatta il valore come euro con separatore di migliaia (punto) e decimali (virgola)
                      return new Intl.NumberFormat('it-IT', {
                        style: 'currency',
                        currency: 'EUR',
                        minimumFractionDigits: 2
                      }).format(value).replace('€', '') + ' €';
                    }}
                    labelFormatter={(label) => `Mese: ${label}`}
                  />
                  <Bar dataKey="incassati" fill="#84a0d9" />
                  <Bar dataKey="non incassati" fill="#d99284" />
                </BarChart>
              </ResponsiveContainer>
            )}
          </Grid>
        </Grid>
      </Paper>

      {/* Secondo Box con il selettore e la progress bar */}
      <Paper elevation={3} style={{ width: '100%', maxWidth: 1200, padding: '20px', marginTop: '20px' }}>
        <Box mb={4} textAlign="center">
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Stato Pagamenti Aziende
          </Typography>
          <Autocomplete
            id="azienda"
            value={azienda}
            onChange={(event, newValue) => {
              handleCompanyChange(newValue)
            }}
            options={aziende.map((az) => az.id)}
            getOptionLabel={(id) => {
              const a = aziende.filter(az => az.id == id)[0];
              return `${a.nome} (${a.id})`;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Azienda"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Box>
        {azienda && (
          <Box mt={4} width="100%" textAlign="center">
            <Typography variant="h6" gutterBottom>
              Stato dei Pagamenti:
            </Typography>
            <Box sx={{ width: '100%', maxWidth: 600, margin: '0 auto' }}>
              <LinearProgress variant="determinate" value={payments.completedPercentage || 0} sx={{ height: 8, borderRadius: 4 }} />
            </Box>
            <Typography variant="body2" color="text.secondary" sx={{ marginTop: 1 }}>
              {payments.completedPercentage || 0}% completato ({payments.daPagare}€ da pagare)
            </Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default Fatturato;
