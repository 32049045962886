import React, { useState, useEffect } from 'react';
import {
  TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, IconButton, Box,
  Tooltip, InputAdornment, Grid, MenuItem, Select,
} from '@mui/material';
import {
  Close as CloseIcon, Delete as DeleteIcon, Upload as UploadIcon, Download as DownloadIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';

import axios from 'axios';
import utils from '../../utils';

const months = [
  'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno',
  'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'
];

const ArchivioDialog = ({ openDialog, setOpenDialog, setError, azienda, anno, mese }) => {
  const [aziendaFiles, setAzienzaFiles] = useState([]);
  const [sortField, setSortField] = useState('');
  const [sortAsc, setSortAsc] = useState(true);
  const [fileSearchTerm, setFileSearchTerm] = useState('');
  const [allAzienzaFiles, setAllAzienzaFiles] = useState([]);
  const [operatorMap, setOperatorMap] = useState({});

  const [selectedYear, setSelectedYear] = useState(anno || new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState((mese || Number(mese) === 0) ? mese : new Date().getMonth());

  const years = Array.from({ length: new Date().getFullYear() - 2023 }, (_, i) => 2025 + i);

  const loadOps = async () => {
    try {
      const res = await axios.get(`/api/listaop`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error, ops } = res.data;
      if (success) {
        const mappa = {}
        for (const op of ops) {
          mappa[op.id] = op.nome;
        }
        setOperatorMap(mappa);
      } else {
        console.error(error);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const loadFiles = async (id) => {
    try {
      const res = await axios.get(`/api/getuser?id=${id}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAllAzienzaFiles(res.data.user.files_paghe);
        setAzienzaFiles(res.data.user.files_paghe);
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("data", JSON.stringify({
        id: azienda.id, name: file.name, section: 'paghe', month: selectedMonth, year: selectedYear
      }));

      const res = await axios.post("/api/caricafileazienda", formData, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });

      const { success, error, fileId } = res.data;
      if (success) {
        const allFiles = [...allAzienzaFiles, {
          id: fileId, name: file.name, date: new Date().toISOString(),
          month: selectedMonth, year: selectedYear
        }]
        setAllAzienzaFiles(allFiles);
        setAzienzaFiles(allFiles);
        setFileSearchTerm('');
      } else {
        setError(error);
      }
    } catch (err) {
      setError("Errore durante il caricamento del file.");
      console.error(err);
    }
  };

  const handleFileDelete = async (fileId) => {
    try {
      const res = await axios.post(`/api/eliminafileazienda`, { id: fileId, section: 'paghe', aziendaId: azienda.id }, {
        headers: utils.getAuthHeaders(),
      });

      const { success, error } = res.data;
      if (success) {
        const allFiles = allAzienzaFiles.filter(f => f.id != fileId);
        setAllAzienzaFiles(allFiles);
        setAzienzaFiles(allFiles);
        setFileSearchTerm('');
      } else {
        setError(error);
      }
    } catch (err) {
      setError("Errore durante l'eliminazione del file.");
      console.error(err);
    }
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortAsc(!sortAsc);
    } else {
      setSortAsc(true);
      setSortField(field);
    }

    const sortedFiles = [...aziendaFiles].sort((a, b) => {
      if (field === 'date') {
        if (sortAsc) return new Date(a[field]) > new Date(b[field]) ? 1 : -1;
        return new Date(a[field]) < new Date(b[field]) ? 1 : -1;
      } else {
        if (sortAsc) return a[field] > b[field] ? 1 : -1;
        return a[field] < b[field] ? 1 : -1;
      }
    });
    setAzienzaFiles(sortedFiles);
  };

  useEffect(() => {
    loadOps();
  }, []);

  useEffect(() => {
    if (azienda) {
      loadFiles(azienda.id);
    }
  }, [azienda]);

  const handleFileSearchChange = (event) => {
    const value = event.target.value;
    setFileSearchTerm(value);
    if (value) {
      setAzienzaFiles(
        allAzienzaFiles.filter((file) =>
          file.name.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setAzienzaFiles(allAzienzaFiles);
    }
  };

  const handleClearFileSearch = () => {
    setFileSearchTerm('');
    setAzienzaFiles(allAzienzaFiles);
  };

  const filteredFiles = aziendaFiles.filter(f => f.month == selectedMonth && f.year == selectedYear);

  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      fullWidth
      maxWidth="xl"
      PaperProps={{ style: { borderRadius: '10px' } }}
    >
      <DialogTitle sx={{ marginBottom: 0 }}>
        Documenti di {azienda?.ragione_sociale}
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => setOpenDialog(false)}
          aria-label="close"
          style={{ position: 'absolute', right: 15, top: -0 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{
          height: '600px',
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          scrollbarColor: 'transparent transparent',
          display: 'flex',
        }}
      >
        <Box width="100%">
          <Grid container spacing={2} alignItems="center" mb={1} mt={1}>
            <Grid item xs={12} sm={6} md={3}>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" fontWeight="bold" gutterBottom style={{ margin: '8px', color: 'gray' }}>
                  Carica File Azienda
                </Typography>
                <Tooltip title="Carica Documenti">
                  <IconButton
                    component="label"
                    color="primary"
                    style={{ marginLeft: "10px" }}
                  >
                    <UploadIcon />
                    <input
                      type="file"
                      hidden
                      onChange={handleFileUpload}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                variant="outlined"
                fullWidth
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                variant="outlined"
                fullWidth
              >
                {months.map((month, index) => (
                  <MenuItem key={index} value={index}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                value={fileSearchTerm}
                onChange={handleFileSearchChange}
                label="Cerca file"
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear search"
                        onClick={handleClearFileSearch}
                        edge="end"
                      >
                        {fileSearchTerm ? <CloseIcon /> : null}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <TableContainer component={Paper} style={{ borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: '#333' }}>
                  <TableCell
                    style={{ padding: '16px', backgroundColor: '#333', fontWeight: 'bold', cursor: 'pointer', position: 'relative', color: '#fff', textAlign: 'center' }}
                    onClick={() => handleSort('opId')}
                  >
                    <Tooltip title={sortField === 'opId' && sortAsc ? "Ordina per Caricato da Decrescente" : "Ordina per Caricato da Crescente"} placement="top">
                      <span>
                        Caricato da {sortField === 'opId' ? (sortAsc ? '▲' : '▼') : ''}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    style={{ padding: '16px', backgroundColor: '#333', fontWeight: 'bold', cursor: 'pointer', position: 'relative', color: '#fff', textAlign: 'center' }}
                    onClick={() => handleSort('name')}
                  >
                    <Tooltip title={sortField === 'name' && sortAsc ? "Ordina per Nome Decrescente" : "Ordina per Nome Crescente"} placement="top">
                      <span>
                        Nome File {sortField === 'name' ? (sortAsc ? '▲' : '▼') : ''}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    style={{ padding: '16px', fontWeight: 'bold', cursor: 'pointer', position: 'relative', color: '#fff', textAlign: 'center' }}
                    onClick={() => handleSort('date')}
                  >
                    <Tooltip title={sortField === 'date' && sortAsc ? "Ordina per Data Meno Recente" : "Ordina per Data Più Recente"} placement="top">
                      <span>
                        Data Inserimento {sortField === 'date' ? (sortAsc ? '▲' : '▼') : ''}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ padding: '16px', fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Azioni</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredFiles.map((file) => (
                  <TableRow key={file.id}>
                    <TableCell style={{ width: '25%', textAlign: 'center' }}>{file.opId ? operatorMap[file.opId] : ""}</TableCell>
                    <TableCell style={{ width: '25%', textAlign: 'center' }}>{file.name}</TableCell>
                    <TableCell style={{ width: '25%', textAlign: 'center' }}>{new Date(file.date).toLocaleDateString()}</TableCell>
                    <TableCell style={{ width: '25%', textAlign: 'center' }}>
                      <Tooltip title="Visualizza">
                        <IconButton disabled={!file.name.endsWith('.pdf')} onClick={() => utils.opOpenPdfInNewTab(file.id)} color="primary">
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Scarica">
                        <IconButton onClick={() => utils.opDownload(file.id)} color="success">
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Elimina">
                        <IconButton onClick={() => handleFileDelete(file.id)} color="error">
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
                {filteredFiles.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3} align="center" style={{ textAlign: 'center' }}>
                      Nessun file caricato.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)} color="primary">Chiudi</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArchivioDialog;
