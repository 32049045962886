import React, { useState, useEffect } from 'react';
import {
  Grid, Autocomplete, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Snackbar, CircularProgress,
  Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, IconButton, Box, Pagination, MenuItem, Select, Divider,
  Tooltip, Tab, Tabs, useMediaQuery, useTheme, InputAdornment,
} from '@mui/material';
import {
  Close as CloseIcon, Add as AddIcon, Delete as DeleteIcon, Upload as UploadIcon, Download as DownloadIcon,
  Visibility as VisibilityIcon, Remove as RemoveIcon,
} from '@mui/icons-material';

import axios from 'axios';
import utils from '../../utils';

const OpAmmDipendenti = () => {
  const [aziende, setAziende] = useState([]);
  const [dipendenti, setDipendenti] = useState([]);
  const [allDipendenti, setAllDipendenti] = useState([]);
  const [selectedAzienda, setSelectedAzienda] = useState(null);
  const [selectedDipendente, setSelectedDipendente] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isAdding, setIsAdding] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [dipendenteToDelete, setDipendenteToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const [dipendenteFiles, setDipendenteFiles] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  const [sortField, setSortField] = useState('');
  const [sortAsc, setSortAsc] = useState(true);
  const [fileSearchTerm, setFileSearchTerm] = useState('');
  const [allDipendenteFiles, setAllDipendenteFiles] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    loadAziende();
  }, []);

  useEffect(() => {
    if (page > Math.ceil(dipendenti.length / rowsPerPage) - 1) {
      setPage(0); // Resetta la pagina se il numero di dipendenti è inferiore rispetto alla pagina corrente
    }
  }, [dipendenti, rowsPerPage, page]);

  useEffect(() => {
    if (selectedAzienda) {
      loadDipendenti(selectedAzienda);
    }
  }, [selectedAzienda]);

  // Per i Costum Fields 
  useEffect(() => {
    setTabValue(0);
    if (selectedDipendente && selectedDipendente.customFields) {
      setCustomFields(selectedDipendente.customFields);
    } else {
      setCustomFields([]);
    }
    if (selectedDipendente && selectedDipendente.files) {
      setDipendenteFiles(selectedDipendente.files);
    } else {
      setDipendenteFiles([]);
    }
  }, [selectedDipendente]);

  const loadAziende = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`/api/aziendeeop`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAziende(res.data.aziende);
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  };

  const loadDipendenti = async (userId) => {
    setLoading(true);
    try {
      const res = await axios.get('/api/dipendenti?id=' + userId, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAllDipendenti(res.data.dipendenti);
        setDipendenti(res.data.dipendenti);
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  };

  const handleRowClick = (dipendente) => {
    setSelectedDipendente(dipendente);
    setIsAdding(false); // Imposta isAdding su false quando si seleziona un dipendente esistente
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedDipendente(null);
  };

  // Funzione per salvare
  const handleSaveChanges = async () => {
    if (selectedDipendente.data_assunzione && selectedDipendente.data_assunzione.length != 10) {
      setError("Inserire la data di assunzione in forma: 01/01/2024");
      return;
    }
    if (selectedDipendente.data_cessazione && selectedDipendente.data_cessazione.length != 10) {
      setError("Inserire la data di scadenza contratto in forma: 01/01/2024");
      return;
    }
    if (selectedDipendente.cod_fiscale && selectedDipendente.cod_fiscale.length != 16) {
      setError("Il codice fiscale deve avere 16 caratteri. Inserire degli zeri iniziali per codici esteri.");
      return;
    }
    setLoading(true);
    try {
      const res = await axios.post(`/api/${isAdding ? 'crea' : 'aggiorna'}dipendente`,
        { id: isAdding ? selectedAzienda : selectedDipendente.id, dipendente: selectedDipendente, customFields }, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        // if (isAdding) {
        return window.location.reload();
        // }
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  };

  // Paginazione
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1); // Modifica qui in modo che `Pagination` e la pagina siano sincronizzati correttamente
  };

  // Funzione per cambiare il numero di righe per pagina
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Torna alla prima pagina se si cambia il numero di righe per pagina
  };

  // Autocomplete
  const handleAutocompleteChange = (event) => {
    const value = event.target.value;
    if (value && value.trim() !== '') {
      setDipendenti(
        allDipendenti.filter((dipendente) =>
          `${dipendente.nome} ${dipendente.cognome}`.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setDipendenti(allDipendenti); // Mostra tutti i dipendenti se non c'è input
    }
    setPage(0);
    setSearchTerm(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedDipendente({
      ...selectedDipendente,
      [name]: value,
    });
  };

  const filteredDipendenti = dipendenti.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Funzioni Per il Dialog di Delete Dipendente
  const handleDelete = (dipendente) => {
    setDipendenteToDelete(dipendente);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    setOpenDeleteDialog(false);
    setLoading(true);
    try {
      const res = await axios.post("/api/eliminadipendente", { id: dipendenteToDelete.id }, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        return window.location.reload();
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  };

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
    setDipendenteToDelete(null);
  };

  // Funzioni Upload Download e Preview e Delete
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("data", JSON.stringify({
        id: selectedDipendente.id, name: file.name, userId: selectedAzienda
      }));

      const res = await axios.post("/api/caricafiledipendente", formData, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });

      const { success, error, fileId } = res.data;
      if (success) {
        setDipendenteFiles(prevFiles => [...prevFiles, { id: fileId, name: file.name, date: Date.now() }]);
        await loadDipendenti(selectedAzienda);
      } else {
        setError(error);
      }
    } catch (err) {
      setError("Errore durante il caricamento del file.");
    }
    setLoading(false);
  };

  const handleFileDelete = async (fileId) => {
    setLoading(true);
    try {
      const res = await axios.post(`/api/eliminafiledipendente`, { id: fileId, dipId: selectedDipendente.id }, {
        headers: utils.getAuthHeaders(),
      });

      const { success, error } = res.data;
      if (success) {
        setDipendenteFiles(prevFiles => prevFiles.filter(f => f.id != fileId));
        await loadDipendenti(selectedAzienda);
      } else {
        setError(error);
      }
    } catch (err) {
      setError("Errore durante l'eliminazione del file.");
    }
    setLoading(false);
  };

  // Funzioni Costum Fields 
  const handleAddCustomField = () => {
    setCustomFields([...customFields, { id: Date.now(), name: '', value: '', type: 'text' }]);
  };

  const handleRemoveCustomField = (id) => {
    setCustomFields(customFields.filter(field => field.id !== id));
  };

  const handleCustomFieldChange = (id, field, value) => {
    setCustomFields(customFields.map(f => f.id === id ? { ...f, [field]: value } : f));
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Funzione per l ordinamento
  const handleSort = (field) => {
    if (sortField === field) {
      setSortAsc(!sortAsc);
    } else {
      setSortAsc(true);
      setSortField(field);
    }

    const sortedFiles = [...dipendenteFiles].sort((a, b) => {
      if (field === 'date') {
        if (sortAsc) return new Date(a[field]) > new Date(b[field]) ? 1 : -1;
        return new Date(a[field]) < new Date(b[field]) ? 1 : -1;
      } else {
        if (sortAsc) return a[field] > b[field] ? 1 : -1;
        return a[field] < b[field] ? 1 : -1;
      }
    });
    setDipendenteFiles(sortedFiles);
  };


  // Aggiorna la logica per impostare i file del dipendente e la copia completa
  useEffect(() => {
    if (selectedDipendente && selectedDipendente.files) {
      setDipendenteFiles(selectedDipendente.files);
      setAllDipendenteFiles(selectedDipendente.files); // Imposta la copia completa
    } else {
      setDipendenteFiles([]);
      setAllDipendenteFiles([]); // Imposta la copia completa
    }
  }, [selectedDipendente]);

  // Aggiungi una funzione per gestire il cambiamento del termine di ricerca dei file
  const handleFileSearchChange = (event) => {
    const value = event.target.value;
    setFileSearchTerm(value);
    if (value) {
      setDipendenteFiles(
        allDipendenteFiles.filter((file) =>
          file.name.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setDipendenteFiles(allDipendenteFiles);
    }
  };

  // Aggiungi una funzione per cancellare il termine di ricerca dei file
  const handleClearFileSearch = () => {
    setFileSearchTerm('');
    setDipendenteFiles(allDipendenteFiles);
  };

  return (
    <Grid container spacing={2} style={{ padding: '10px' }}>
      <Grid item xs={12}>
        <Typography variant="h4" align="center" fontWeight="bold">
          Dipendenti per Azienda
        </Typography>
      </Grid>

      <Divider sx={{ my: 2 }} />

      {/* Azienda */}
      <Grid item xs={12} md={6}>
        <Paper style={{ padding: '10px', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
          <Autocomplete
            id="azienda"
            value={selectedAzienda}
            onChange={(event, newValue) => {
              setSelectedAzienda(newValue);
              setSelectedDipendente(null); // Reset selezione dipendente
            }}
            options={aziende.map((az) => az.id)}
            getOptionLabel={(id) => {
              const a = aziende.filter(az => az.id == id)[0];
              return `${a.nome} (${a.id})`;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Azienda"
                variant="outlined"
              />
            )}
            fullWidth
            style={{ borderRadius: '10px' }}
          />
        </Paper>
      </Grid>

      {/* Dipendente */}
      <Grid item xs={12} md={6}>
        <Paper style={{ padding: '10px', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', display: 'flex', alignItems: 'center', position: 'relative' }}>
          <div style={{ flex: 1, marginRight: '10px' }}>
            <TextField
              value={searchTerm}
              onChange={handleAutocompleteChange}
              label="Cerca per nome/cognome"
              fullWidth
              style={{ borderRadius: '10px' }}
            />
          </div>
          <Tooltip title="Aggiungi Dipendente">
            <IconButton
              style={{ position: 'relative', top: '0', right: '0' }}
              color="primary"
              onClick={() => {
                setIsAdding(true);
                setSelectedDipendente({
                  nome: '',
                  cognome: '',
                  data_nascita: '',
                  luogo_nascita: '',
                  nazionalita: '',
                  cittadinanza: '',
                  cod_fiscale: '',
                  titolo_di_studio: '',
                  residenza: '',
                  domicilio: '',
                  data_assunzione: '',
                  data_cessazione: '',
                  tipo_contratto: '',
                  sede_lavoro: '',
                  note: ''
                });
                setOpenDialog(true);
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper style={{ padding: '10px', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
          {loading ? <CircularProgress sx={{ display: 'block', margin: '0 auto' }} /> :
            <TableContainer component={Paper} style={{ borderRadius: '10px' }}>
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: '#333', color: '#fff' }}>
                    <TableCell style={{ width: '20%', color: '#fff', fontWeight: 'bold' }}>Cognome</TableCell>
                    <TableCell style={{ width: '20%', color: '#fff', fontWeight: 'bold' }}>Nome</TableCell>
                    <TableCell style={{ width: '20%', color: '#fff', fontWeight: 'bold' }}>Data di Nascita</TableCell>
                    <TableCell style={{ width: '20%', color: '#fff', fontWeight: 'bold' }}>Codice Fiscale</TableCell>
                    <TableCell style={{ width: '20%', color: '#fff', fontWeight: 'bold' }}>Tipo Contratto</TableCell>
                    <TableCell style={{ width: '10%', color: '#fff', fontWeight: 'bold' }}>Elimina</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredDipendenti.map((dipendente) => (
                    <TableRow
                      key={dipendente.id}
                      hover
                      onClick={() => handleRowClick(dipendente)}
                      style={{ backgroundColor: '#f5f5f5' }}
                    >
                      <TableCell>{dipendente.cognome}</TableCell>
                      <TableCell>{dipendente.nome}</TableCell>
                      <TableCell>{dipendente.data_nascita}</TableCell>
                      <TableCell>{dipendente.cod_fiscale}</TableCell>
                      <TableCell>{dipendente.tipo_contratto}</TableCell>
                      <TableCell>
                        <IconButton onClick={(e) => { e.stopPropagation(); handleDelete(dipendente); }}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>}

          {/* Paginazione */}
          <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="center" alignItems="center" mt={2}>
            <Select
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              variant="outlined"
              style={{ marginRight: '8px', maxWidth: '80px', height: '35px', marginBottom: { xs: '8px', sm: '0' } }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
            <Pagination
              count={Math.ceil(dipendenti.length / rowsPerPage)} // Numero totale di pagine
              page={page + 1} // `Pagination` usa un base-1 system
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </Box>
        </Paper>
      </Grid>

      {/* Dialog per Conferma Elimina Dipendente */}
      <Dialog open={openDeleteDialog} onClose={handleCancelDelete}>
        <DialogTitle>Conferma</DialogTitle>
        <DialogContent>
          Sei sicuro di voler eliminare il dipendente {dipendenteToDelete?.nome} {dipendenteToDelete?.cognome}?
          Questa operazione è irreversibile. I dati del dipendente rimarranno comunque salvati per lo storico
          delle presenze e dei cedolini.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">Annulla</Button>
          <Button onClick={handleConfirmDelete} color="warning">Conferma</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog per Detail Dipendente e per Add */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="xl" // Imposta una larghezza massima fissa
        PaperProps={{ style: { borderRadius: '10px' } }}
      >
        <DialogTitle>
          {isAdding ? 'Aggiungi Nuovo Dipendente' : 'Scheda Dipendente'}
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
            style={{ position: 'absolute', right: 15, top: -0 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            height: '600px', // Altezza fissa del dialogo
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            scrollbarColor: 'transparent transparent',
            display: 'flex',
          }}
        >
          <Box width="100%">
            {selectedDipendente && (
              <Box padding={0}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  orientation="horizontal"
                  style={{ marginBottom: '8px' }}
                >
                  <Tab label="Panoramica" />
                  <Tab label="Campi Personalizzati" />
                  {!isAdding && <Tab label="Documenti" />}
                </Tabs>
                {tabValue === 0 && (
                  <Grid container spacing={1} direction="column">

                    {/* Prima Riga Dati del Dipendente */}
                    <Grid item xs={12}>
                      <Paper variant="outlined" style={{ padding: '10px', borderRadius: '10px', marginBottom: '10px' }}>
                        <Typography variant="body1" style={{ color: 'gray', marginBottom: '10px' }}><strong>Dipendente</strong></Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={3}>
                            <TextField
                              fullWidth
                              label="Nome"
                              name="nome"
                              value={selectedDipendente.nome}
                              onChange={handleInputChange}
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              fullWidth
                              label="Cognome"
                              name="cognome"
                              value={selectedDipendente.cognome}
                              onChange={handleInputChange}
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              fullWidth
                              label="Data di Nascita"
                              name="data_nascita"
                              value={selectedDipendente.data_nascita}
                              onChange={handleInputChange}
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              fullWidth
                              label="Luogo di Nascita"
                              name="luogo_nascita"
                              value={selectedDipendente.luogo_nascita}
                              onChange={handleInputChange}
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              fullWidth
                              label="Codice Fiscale"
                              name="cod_fiscale"
                              value={selectedDipendente.cod_fiscale}
                              onChange={handleInputChange}
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              fullWidth
                              label="Titolo di Studio"
                              name="titolo_di_studio"
                              value={selectedDipendente.titolo_di_studio || ''}
                              onChange={handleInputChange}
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                            />
                          </Grid>

                          <Grid item xs={12} md={3}>
                            <TextField
                              fullWidth
                              label="Nazionalità"
                              name="nazionalita"
                              value={selectedDipendente.nazionalita}
                              onChange={handleInputChange}
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              fullWidth
                              label="Cittadinanza"
                              name="cittadinanza"
                              value={selectedDipendente.cittadinanza}
                              onChange={handleInputChange}
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              fullWidth
                              label="Residenza"
                              name="residenza"
                              value={selectedDipendente.residenza}
                              onChange={handleInputChange}
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              fullWidth
                              label="Domicilio"
                              name="domicilio"
                              value={selectedDipendente.domicilio}
                              onChange={handleInputChange}
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>

                    {/* Seconda Riga Dati del Contratto */}
                    <Grid item xs={12}>
                      <Paper variant="outlined" style={{ padding: '10px', borderRadius: '10px', marginBottom: '10px' }}>
                        <Typography variant="body1" style={{ color: 'gray', marginBottom: '10px' }}><strong>Contratto</strong></Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={3}>
                            <TextField
                              fullWidth
                              label="Mansione e Livello"
                              name="mansione_livello"
                              value={selectedDipendente.mansione_livello}
                              onChange={handleInputChange}
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              fullWidth
                              label="Tipo Contratto"
                              name="tipo_contratto"
                              value={selectedDipendente.tipo_contratto}
                              onChange={handleInputChange}
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              fullWidth
                              label="Data Assunzione"
                              name="data_assunzione"
                              value={selectedDipendente.data_assunzione || ''}
                              onChange={handleInputChange}
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              fullWidth
                              label="Data Scadenza Contratto"
                              name="data_cessazione"
                              value={selectedDipendente.data_cessazione || ''}
                              onChange={handleInputChange}
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              fullWidth
                              label="Distribuzione Orario"
                              name="distribuzione_orario"
                              value={selectedDipendente.distribuzione_orario || ''}
                              onChange={handleInputChange}
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              fullWidth
                              label="Tipo di Orario"
                              name="tipo_orario"
                              value={selectedDipendente.tipo_orario || ''}
                              onChange={handleInputChange}
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              fullWidth
                              label="Sede di Lavoro"
                              name="sede_lavoro"
                              value={selectedDipendente.sede_lavoro || ''}
                              onChange={handleInputChange}
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                            />
                          </Grid>

                        </Grid>
                      </Paper>
                    </Grid>

                    {/* Terza Riga per Retribuzione */}
                    <Grid item xs={12}>
                      <Paper variant="outlined" style={{ padding: '10px', borderRadius: '10px', marginBottom: '10px' }}>
                        <Typography variant="body1" style={{ color: 'gray', marginBottom: '10px' }}><strong>Retribuzione</strong></Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={3}>
                            <TextField
                              fullWidth
                              label="Netto Mensile"
                              name="netto_mensile"
                              value={selectedDipendente.netto_mensile || ''}
                              onChange={handleInputChange}
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              fullWidth
                              label="RAL Annuale"
                              name="ral_annuale"
                              value={selectedDipendente.ral_annuale || ''}
                              onChange={handleInputChange}
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>

                    {!isAdding && <Grid item xs={12}>
                      <Paper variant="outlined" style={{ padding: '10px', borderRadius: '10px', marginBottom: '10px' }}>
                        <Typography variant="body1" style={{ color: 'gray', marginBottom: '10px' }}><strong>Timbratore</strong></Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={3}>
                            <TextField
                              fullWidth
                              label="Login timbratore"
                              name="login"
                              value={selectedDipendente.cod_fiscale?.substring(0, 6).toUpperCase() + selectedDipendente.id || ''}
                              disabled
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              fullWidth
                              label="Password timbratore"
                              name="password"
                              value={selectedDipendente.password || ''}
                              onChange={handleInputChange}
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>}

                    {/* Quarta Riga Campi Personalizzati */}
                    {customFields.length > 0 && (
                      <Grid item xs={12}>
                        <Paper variant="outlined" style={{ padding: '10px', borderRadius: '10px', marginBottom: '10px' }}>
                          <Typography variant="body1" style={{ color: 'gray', marginBottom: '10px' }}><strong>Campi Personalizzati</strong></Typography>
                          <Grid container spacing={2}>
                            {customFields.map((field) => (
                              <Grid item xs={12} md={3} key={field.id}>
                                <TextField
                                  fullWidth
                                  label={field.name}
                                  value={field.value}
                                  variant="outlined"
                                  style={{ borderRadius: '10px' }}
                                  disabled
                                  type={field.type === 'date' || field.type === 'deadline' ? 'date' : 'text'}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Paper>
                      </Grid>
                    )}

                    {/* Colonna Inferiore per "Note" */}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Note"
                        name="note"
                        value={selectedDipendente.note}
                        onChange={handleInputChange}
                        variant="outlined"
                        style={{ borderRadius: '10px', marginTop: '8px' }}
                        multiline
                        rows={4}
                      />
                    </Grid>
                  </Grid>
                )}

                {/* Tabs */}
                {!isAdding && tabValue === 2 && (
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" mb={1}>
                      <Typography variant="body1" fontWeight="bold" gutterBottom style={{ margin: '8px', color: 'gray' }}>
                        File Dipendente
                      </Typography>
                      <Tooltip title="Carica Documenti">
                        <IconButton
                          component="label"
                          color="primary"
                          style={{ marginRight: "10px" }}
                        >
                          <UploadIcon />
                          <input
                            type="file"
                            hidden
                            onChange={handleFileUpload}
                          />
                        </IconButton>
                      </Tooltip>
                      <TextField
                        value={fileSearchTerm}
                        onChange={handleFileSearchChange}
                        label="Cerca file"
                        variant="outlined"
                        style={{ borderRadius: '10px', marginLeft: 'auto' }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="clear search"
                                onClick={handleClearFileSearch}
                                edge="end"
                              >
                                {fileSearchTerm ? <CloseIcon /> : null}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <TableContainer component={Paper} style={{ borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                      <Table>
                        <TableHead>
                          <TableRow style={{ backgroundColor: '#333' }}>
                            <TableCell
                              style={{ fontWeight: 'bold', cursor: 'pointer', position: 'relative', color: '#fff', textAlign: 'center' }}
                              onClick={() => handleSort('name')}
                            >
                              <Tooltip title={sortField === 'name' && sortAsc ? "Ordina per Nome Decrescente" : "Ordina per Nome Crescente"} placement="top">
                                <span>
                                  Nome File {sortField === 'name' ? (sortAsc ? '▲' : '▼') : ''}
                                </span>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: 'bold', cursor: 'pointer', position: 'relative', color: '#fff', textAlign: 'center' }}
                              onClick={() => handleSort('date')}
                            >
                              <Tooltip title={sortField === 'date' && sortAsc ? "Ordina per Data Meno Recente" : "Ordina per Data Più Recente"} placement="top">
                                <span>
                                  Data Inserimento {sortField === 'date' ? (sortAsc ? '▲' : '▼') : ''}
                                </span>
                              </Tooltip>
                            </TableCell>
                            <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Azioni</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dipendenteFiles.map((file) => (
                            <TableRow key={file.id}>
                              <TableCell style={{ width: '33.33%', textAlign: 'center' }}>{file.name}</TableCell>
                              <TableCell style={{ width: '33.33%', textAlign: 'center' }}>{new Date(file.date).toLocaleDateString()}</TableCell>
                              <TableCell style={{ width: '33.33%', textAlign: 'center' }}>
                                <Tooltip title="Visualizza">
                                  <IconButton disabled={!file.name.endsWith('.pdf')} onClick={() => utils.opOpenPdfInNewTab(file.id)} color="primary">
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Scarica">
                                  <IconButton onClick={() => utils.opDownload(file.id)} color="success">
                                    <DownloadIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Elimina">
                                  <IconButton onClick={() => handleFileDelete(file.id)} color="error">
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                          {dipendenteFiles.length === 0 && (
                            <TableRow>
                              <TableCell colSpan={3} align="center" style={{ textAlign: 'center' }}>
                                Nessun file caricato.
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}

                {tabValue === 1 && (
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" mb={2}>
                      <Typography variant="body1" style={{ margin: '8px', color: 'gray' }}><strong>Campi Personalizzati</strong></Typography>
                      <Tooltip title="Aggiungi Campo">
                        <IconButton onClick={handleAddCustomField} color="primary">
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>                    {customFields.map((field) => (
                      <Grid container spacing={2} alignItems="center" key={field.id} style={{ marginBottom: '8px' }}>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            fullWidth
                            value={field.name}
                            onChange={(e) => handleCustomFieldChange(field.id, 'name', e.target.value)}
                            placeholder="Nome campo"
                            variant="outlined"
                            style={{ borderRadius: '10px' }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Select
                            value={field.type}
                            onChange={(e) => handleCustomFieldChange(field.id, 'type', e.target.value)}
                            variant="outlined"
                            style={{ borderRadius: '10px' }}
                          >
                            <MenuItem value="text" title="Inserisci un campo di testo">Testo</MenuItem>
                            <MenuItem value="date" title="Inserisci una data senza notifica">Data</MenuItem>
                            <MenuItem value="deadline" title="Imposta una scadenza per ricevere una notifica">Scadenza</MenuItem>
                            <MenuItem value="currency" title="Inserisci un valore di valuta">Valuta</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          {field.type === 'text' ? (
                            <TextField
                              fullWidth
                              value={field.value}
                              onChange={(e) => handleCustomFieldChange(field.id, 'value', e.target.value)}
                              placeholder="Valore campo"
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                            />
                          ) : field.type === 'date' || field.type == 'deadline' ? (
                            <TextField
                              fullWidth
                              type="date"
                              value={field.value}
                              onChange={(e) => handleCustomFieldChange(field.id, 'value', e.target.value)}
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          ) : (
                            <TextField
                              fullWidth
                              value={field.value}
                              onChange={(e) => handleCustomFieldChange(field.id, 'value', e.target.value)}
                              placeholder="Valuta"
                              variant="outlined"
                              style={{ borderRadius: '10px' }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">€</InputAdornment>,
                              }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <IconButton onClick={() => handleRemoveCustomField(field.id)} color="gray">
                            <RemoveIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                )}

              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Chiudi</Button>
          <Button
            onClick={() => handleSaveChanges()}
            color="primary"
            variant="contained"
          >
            {isAdding ? 'Aggiungi' : 'Salva'}
          </Button>
        </DialogActions>
      </Dialog>

      {error && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setError('')}
          message={error}
        />
      )}

    </Grid >
  );
};

export default OpAmmDipendenti;
