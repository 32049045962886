import React, { useState, useEffect } from "react";
import {
  Button, Container, Typography, Dialog, DialogContent, DialogTitle,
  Grid, TextField, Box, IconButton, Tooltip, Select, MenuItem, Collapse,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  FormControl, InputLabel, Snackbar, Chip,
} from "@mui/material";
import {
  Event, Email, AccessTime, CheckCircle, PauseCircleFilled, PlayCircleFilled,
  ExitToApp, ExpandMore, ExpandLess, CheckCircleOutline, Pending, Cancel, RemoveCircle, AddCircle,
  Visibility, Download, Upload, Delete,
} from "@mui/icons-material";
import { DatePicker, TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/it';
import JSZip from 'jszip';
import axios from 'axios';
import utils from "../../utils";
import NotificationPermissionRequest from '../NotificationRequest';

const ts2Time = ms => {
  if (!ms) {
    return "";
  }
  return new Date(ms).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false, // Usa il formato 24 ore
  });
}

const formatDate = (date) => {
  const options = { day: 'numeric', weekday: 'short' };
  const formattedDate = date.toLocaleDateString('it-IT', options).replace(/ /g, ' ');
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};

const calculateTotalPause = (pauseArray) => {
  if (!Array.isArray(pauseArray)) {
    return 0;
  }

  return Math.round(pauseArray.reduce((total, pausa) => {
    return total + (pausa.a.t - pausa.da.t) / (1000 * 60); // Convert to minutes
  }, 0));
};

// dateFrom / dateTo => stringhe, perche conta solo il giorno, se c'e' timeFrom, timeTo (timestamps)
// allora dateFrom e dateTo possono essere ignorate nei calcoli e considerato solo il time
const AreaDipendenti = () => {
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());
  const [dialogs, setDialogs] = useState({ event: false, communication: false, timbrature: false });
  const [history, setHistory] = useState({ event: [], communication: [], timbrature: [] });
  const [formData, setFormData] = useState({ event: {}, communication: {}, timbrature: {} });
  const [expanded, setExpanded] = useState({ event: false, communication: false, timbrature: false });
  const [timbraturaState, setTimbraturaState] = useState(null);
  const [lastTimbratura, setLastTimbratura] = useState(null);
  const [position, setPosition] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  useEffect(() => {
    loadTimbratura();
    loadHistories();
    const interval = setInterval(() => setCurrentTime(new Date().toLocaleTimeString()), 1000);
    return () => clearInterval(interval);
  }, []);

  const handleDialog = (type, open) => {
    setDialogs((prev) => ({ ...prev, [type]: open }));
  };

  const handleFormChange = (type, field, value) => {
    setFormData((prev) => ({
      ...prev,
      [type]: { ...prev[type], [field]: value },
    }));
  };

  const loadHistories = async () => {
    try {
      const res = await axios.get('/api/storicorichiestedip', {
        headers: utils.getDipendenteAuthHeaders(), params: { mese: new Date().getMonth(), anno: new Date().getFullYear() }
      });
      const { success, error } = res.data;
      if (success) {
        setHistory({ ...res.data });
      } else {
        setSnackbar({ open: true, message: `ERRORE: ${error}` });
      }
    } catch (error) {
      setSnackbar({ open: true, message: "ERRORE: Si è verificato un errore imprevisto sul nostro server." });
      console.log(error);
    }
  };

  const handleSave = async (type) => {
    let d = type == 'timbrature' ? formData[type].date : formData[type].dateFrom;
    if (!d) {
      setSnackbar({ open: true, message: `Inserire la data` });
      return;
    }
    const giorno = new Date(`${d.$y}-${(d.$M + 1).toString().padStart(2, '0')}-${d.$D.toString().padStart(2, '0')}T12:00:00`);
    const payload = { note: formData[type].note };
    if (type == 'timbrature') {
      if (!formData[type].entrata || !formData[type].uscita) {
        setSnackbar({ open: true, message: `Inserire ora di entrata e uscita` });
        return;
      }
      d = formData[type].entrata.$d;
      d.setFullYear(giorno.getFullYear());
      d.setMonth(giorno.getMonth());
      d.setDate(giorno.getDate());
      payload.entrata = { t: d.getTime() };
      d = formData[type].uscita.$d;
      d.setFullYear(giorno.getFullYear());
      d.setMonth(giorno.getMonth());
      d.setDate(giorno.getDate());
      if (d.getTime() <= payload.entrata.t) {
        d.setDate(d.getDate() + 1);
      }
      payload.uscita = { t: d.getTime() };
      payload.pause = [];
      const formDataPauses = formData[type].pauses || [];
      for (let i = 0; i < formDataPauses.length; i++) {
        const p = formData[type].pauses[i].$d;
        const r = formData[type].rientri[i].$d;
        if (p && r) {
          p.setFullYear(giorno.getFullYear());
          p.setMonth(giorno.getMonth());
          p.setDate(giorno.getDate());
          if (p.getTime() <= payload.entrata.t) {
            p.setDate(p.getDate() + 1);
          }
          r.setFullYear(giorno.getFullYear());
          r.setMonth(giorno.getMonth());
          r.setDate(giorno.getDate());
          if (r.getTime() <= payload.entrata.t) {
            r.setDate(r.getDate() + 1);
          }
          payload.pause.push({ da: { t: p.getTime() }, a: { t: r.getTime() } });
        }
      }
    } else {
      payload.type = formData[type].type;
      if (!formData[type].dateFrom || !formData[type].dateTo) {
        setSnackbar({ open: true, message: `Inserire data inizio e fine` });
        return;
      }
      if (formData[type].dateFrom.$d > formData[type].dateTo.$d) {
        setSnackbar({ open: true, message: `La data di fine deve essere uguale o maggiore della data di inizio` });
        return;
      }
      let d = formData[type].dateFrom;
      payload.dateFrom = `${d.$y}-${(d.$M + 1).toString().padStart(2, '0')}-${d.$D.toString().padStart(2, '0')}`;
      d = formData[type].dateTo;
      const giornoTo = new Date(`${d.$y}-${(d.$M + 1).toString().padStart(2, '0')}-${d.$D.toString().padStart(2, '0')}T12:00:00`);
      payload.dateTo = `${d.$y}-${(d.$M + 1).toString().padStart(2, '0')}-${d.$D.toString().padStart(2, '0')}`;
      if (formData[type].timeFrom && formData[type].timeTo) {
        d = formData[type].timeFrom.$d;
        d.setFullYear(giorno.getFullYear());
        d.setMonth(giorno.getMonth());
        d.setDate(giorno.getDate());
        payload.timeFrom = d.getTime();
        d = formData[type].timeTo.$d;
        d.setFullYear(giornoTo.getFullYear());
        d.setMonth(giornoTo.getMonth());
        d.setDate(giornoTo.getDate());
        payload.timeTo = d.getTime();
        if (payload.timeFrom > payload.timeTo) {
          setSnackbar({ open: true, message: `L'ora di fine deve essere uguale o maggiore dell'ora di inizio per gli eventi di un giorno` });
          return;
        }
      }
    }
    try {
      let file;
      const json = { giorno: giorno.toISOString().substring(0, 10), payload, tipo: payload.type };
      if (!formData[type].files || !formData[type].files.length) {
      } else if (formData[type].files.length == 1) {
        file = formData[type].files[0];
        json.file = formData[type].files[0].name;
      } else {
        const zip = new JSZip();
        for (let i = 0; i < formData[type].files.length; i++) {
          const file = formData[type].files[i];
          zip.file(file.name, file);
        }
        file = await zip.generateAsync({ type: 'blob' });
        json.file = 'files.zip';
      }
      const form = new FormData();
      if (file) {
        form.append("file", file);
      }
      form.append("data", JSON.stringify(json));
      const res = await axios.post(`/api/richiedidip${type}`, form,
        {
          headers: utils.getDipendenteAuthHeaders(),
          "Content-Type": "multipart/form-data",
        });
      const { success, error } = res.data;
      if (success) {
        setFormData((prev) => ({
          ...prev,
          [type]: {},
        }));
        setSnackbar({ open: true, message: `Richiesta inviata con successo!` });
        loadHistories();
      } else {
        setSnackbar({ open: true, message: `ERRORE: ${error}` });
      }
    } catch (error) {
      console.log(error);
      setSnackbar({ open: true, message: "ERRORE: Si è verificato un errore imprevisto sul nostro server." });
    }
    handleDialog(type, false);
  };

  const loadTimbratura = async () => {
    try {
      const timbratura = await fetchTimbratura();
      if (timbratura) {
        if (timbratura.uscita) {
          const date = new Date(timbratura.uscita.t);
          const formattedDate = date.toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$1-$2-$3');
          setLastTimbratura({
            type: "Uscita",
            date: formattedDate,
            time: date.toLocaleTimeString(),
            position: timbratura.uscita.c ? { latitude: timbratura.uscita.c.lat, longitude: timbratura.uscita.c.lon } : null,
          });
          setTimbraturaState("uscita");
        } else if (timbratura.pause.length && timbratura.pause[timbratura.pause.length - 1].a) {
          const rientro = timbratura.pause[timbratura.pause.length - 1].a;
          const date = new Date(rientro.t);
          const formattedDate = date.toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$1-$2-$3');
          setLastTimbratura({
            type: "Rientro",
            date: formattedDate,
            time: date.toLocaleTimeString(),
            position: rientro.c ? { latitude: rientro.c.lat, longitude: rientro.c.lon } : null,
          });
          setTimbraturaState("rientro");
        } else if (timbratura.pause.length) {
          const pausa = timbratura.pause[timbratura.pause.length - 1].da;
          const date = new Date(pausa.t);
          const formattedDate = date.toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$1-$2-$3');
          setLastTimbratura({
            type: "Pausa",
            date: formattedDate,
            time: date.toLocaleTimeString(),
            position: pausa.c ? { latitude: pausa.c.lat, longitude: pausa.c.lon } : null,
          });
          setTimbraturaState("pausa");
        } else {
          const date = new Date(timbratura.entrata.t);
          const formattedDate = date.toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$1-$2-$3');
          setLastTimbratura({
            type: "Entrata",
            date: formattedDate,
            time: date.toLocaleTimeString(),
            position: timbratura.entrata.c ? { latitude: timbratura.entrata.c.lat, longitude: timbratura.entrata.c.lon } : null,
          });
          setTimbraturaState("entrata");
        }
      }
    } catch (err) {
      setSnackbar({ open: true, message: "ERRORE: " + err });
    }
  }

  const handleTimbratura = async (action) => {
    const now = new Date();
    const formattedDate = now.toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$1-$2-$3');

    try {
      const pos = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      const { latitude, longitude } = pos.coords;
      setPosition({ latitude, longitude });
      const err = await sendTimbratura({ tipo: action, coords: { lat: latitude, lon: longitude } });
      if (err) {
        setSnackbar({ open: true, message: "ERRORE: " + err });
        return;
      }
      const newTimbratura = {
        type: action.charAt(0).toUpperCase() + action.slice(1),
        date: formattedDate,
        time: now.toLocaleTimeString(),
        position: { latitude, longitude },
      };
      setLastTimbratura(newTimbratura);
      setTimbraturaState(action);
      setSnackbar({ open: true, message: `Timbratura ${action} registrata!` });
      // Aggiungi qui la logica per salvare l'azione di timbratura
    } catch (error) {
      console.error("Errore nel rilevamento della posizione:", error);
      const err = await sendTimbratura({ tipo: action, coords: null });
      if (err) {
        setSnackbar({ open: true, message: "ERRORE: " + err });
        return;
      }
      const newTimbratura = {
        type: action.charAt(0).toUpperCase() + action.slice(1),
        date: formattedDate,
        time: now.toLocaleTimeString(),
        position: null,
      };
      setLastTimbratura(newTimbratura);
      setTimbraturaState(action);
      setSnackbar({ open: true, message: `Timbratura ${action} registrata senza posizione!` });
      // Aggiungi qui la logica per salvare l'azione di timbratura
    }
  };

  const fetchTimbratura = async () => {
    try {
      const res = await axios.get(`/api/timbratura`, { headers: utils.getDipendenteAuthHeaders(), validateStatus: () => true });
      if (res.status < 400) {
        const { success, error } = res.data;
        if (success) {
          return res.data.timbratura;
        } else {
          throw new Error(error);
        }
      }
    } catch (error) {
      console.log(error);
      throw new Error("Si è verificato un errore imprevisto sul nostro server.");
    }
    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/logindipendenti";
    }
  }

  const sendTimbratura = async timb => {
    try {
      const res = await axios.post(`/api/timbra`, timb, { headers: utils.getDipendenteAuthHeaders() });
      const { success, error } = res.data;
      if (success) {
        return;
      } else {
        return error;
      }
    } catch (error) {
      console.log(error);
      return "Si è verificato un errore imprevisto sul nostro server.";
    }
  }

  const calculateTotalHours = () => {
    if (lastTimbratura.type !== 'Uscita' || !lastTimbratura.entrata || !lastTimbratura.uscita) {
      return 0;
    }

    const totalWorkTime = (new Date(lastTimbratura.uscita) - new Date(lastTimbratura.entrata)) / (1000 * 60 * 60);
    const totalPauseTime = lastTimbratura.pauses?.reduce((total, pause, i) => {
      const rientro = lastTimbratura.rientri?.[i];
      if (pause && rientro) {
        const pauseDuration = (new Date(rientro) - new Date(pause)) / (1000 * 60 * 60);
        return total + pauseDuration;
      }
      return total;
    }, 0) || 0;

    return totalWorkTime - totalPauseTime;
  };

  const renderDialog = (type, title, options) => (
    <Dialog open={dialogs[type]} onClose={() => handleDialog(type, false)} fullWidth maxWidth="md">
      <DialogTitle>{title}
        <Chip
          label="Resetta"
          onClick={() => {
            handleFormChange(type, "date", null);
            handleFormChange(type, "entrata", null);
            handleFormChange(type, "pauses", []);
            handleFormChange(type, "rientri", []);
            handleFormChange(type, "uscita", null);
            handleFormChange(type, "dateFrom", null);
            handleFormChange(type, "dateTo", null);
            handleFormChange(type, "timeFrom", null);
            handleFormChange(type, "timeTo", null);
          }}
          sx={{ cursor: 'pointer', marginLeft: '16px' }}
        />
      </DialogTitle>
      <DialogContent>

        <Box sx={{ pt: 1 }}>
          {type !== "timbrature" && <Typography textAlign="center" color="red" my={1}>
            Inserire "Ora da" e "Ora a" solo nel caso in cui l'evento avviene all'interno di uno stesso turno lavorativo
          </Typography>}
          <Grid container spacing={2}>
            {type === "timbrature" && (
              <>
                <Grid item xs={12} sm={2.4}>
                  <DatePicker
                    label="Data"
                    value={formData[type]?.date || null}
                    onChange={(date) => handleFormChange(type, "date", date)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Grid>
                <Grid item xs={12} sm={2.4}>
                  <TimePicker
                    label="Entrata"
                    value={formData[type]?.entrata || null}
                    onChange={(time) => handleFormChange(type, "entrata", time)}
                    renderInput={(params) => <TextField {...params} fullWidth inputProps={{ style: { overflow: 'hidden' } }} />}
                    ampm={false}
                  />
                </Grid>
                {[...Array(formData[type]?.pauses?.length || 0)].map((_, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12} sm={2.4}>
                      <TimePicker
                        label={`Pausa ${index + 1}`}
                        value={formData[type]?.pauses?.[index] || null}
                        onChange={(time) => handleFormChange(type, `pauses`, formData[type]?.pauses?.map((p, i) => i === index ? time : p))}
                        renderInput={(params) => <TextField {...params} fullWidth inputProps={{ style: { overflow: 'hidden' } }} />}
                        ampm={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2.4}>
                      <TimePicker
                        label={`Rientro ${index + 1}`}
                        value={formData[type]?.rientri?.[index] || null}
                        onChange={(time) => handleFormChange(type, `rientri`, formData[type]?.rientri?.map((r, i) => i === index ? time : r))}
                        renderInput={(params) => <TextField {...params} fullWidth inputProps={{ style: { overflow: 'hidden' } }} />}
                        ampm={false}
                      />
                    </Grid>
                  </React.Fragment>
                ))}
                <Grid item xs={12} sm={2.4}>
                  <TimePicker
                    label="Uscita"
                    value={formData[type]?.uscita || null}
                    onChange={(time) => handleFormChange(type, "uscita", time)}
                    renderInput={(params) => <TextField {...params} fullWidth inputProps={{ style: { overflow: 'hidden' } }} />}
                    ampm={false}
                  />
                </Grid>
                <Grid item xs={12} sm={2.4} textAlign="center">
                  <Typography
                    variant="caption"
                    style={{ color: 'gray', fontStyle: 'italic', display: 'block', marginBottom: '0px' }}
                  >
                    Aggiungi Pause
                  </Typography>
                  <IconButton
                    onClick={() => {
                      if ((formData[type]?.pauses?.length || 0) < 3) {
                        handleFormChange(type, "pauses", [...(formData[type]?.pauses || []), null]);
                        handleFormChange(type, "rientri", [...(formData[type]?.rientri || []), null]);
                      }
                    }}
                  >
                    <AddCircle />
                  </IconButton>
                  {(formData[type]?.pauses?.length || 0) > 0 && (
                    <IconButton
                      onClick={() => {
                        handleFormChange(type, "pauses", formData[type]?.pauses?.slice(0, -1));
                        handleFormChange(type, "rientri", formData[type]?.rientri?.slice(0, -1));
                      }}
                    >
                      <RemoveCircle />
                    </IconButton>
                  )}
                </Grid>
              </>
            )}
            {options && (
              <Grid item xs={12} sm={2.4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Evento</InputLabel>
                  <Select
                    required
                    value={formData[type]?.type || ""}
                    onChange={(e) => handleFormChange(type, "type", e.target.value)}
                    label="Evento"
                  >
                    {options.map((option) => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {type !== "timbrature" && (
              <>
                <Grid item xs={12} sm={2.4}>
                  <DatePicker
                    label="Data Da"
                    value={formData[type]?.dateFrom || null}
                    onChange={(date) => handleFormChange(type, "dateFrom", date)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Grid>
                <Grid item xs={12} sm={2.4}>
                  <DatePicker
                    label="Data A"
                    value={formData[type]?.dateTo || null}
                    onChange={(date) => handleFormChange(type, "dateTo", date)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Grid>
                <Grid item xs={12} sm={2.4}>
                  <TimePicker
                    label="Ora da"
                    value={formData[type]?.timeFrom || null}
                    onChange={(time) => handleFormChange(type, "timeFrom", time)}
                    renderInput={(params) => <TextField {...params} fullWidth inputProps={{ style: { overflow: 'hidden' } }} />}
                    ampm={false}
                  />
                </Grid>
                <Grid item xs={12} sm={2.4}>
                  <TimePicker
                    label="Ora a"
                    value={formData[type]?.timeTo || null}
                    onChange={(time) => handleFormChange(type, "timeTo", time)}
                    renderInput={(params) => <TextField {...params} fullWidth inputProps={{ style: { overflow: 'hidden' } }} />}
                    ampm={false}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                label="Note"
                multiline
                rows={3}
                fullWidth
                value={formData[type]?.note || ""}
                onChange={(e) => handleFormChange(type, "note", e.target.value)}
              />
            </Grid>
            {type == 'timbrature' ? <div /> : <Grid item xs={12}>
              <Box display="flex" alignItems="center" justifyContent="flex-start" mb={1}>
                <Typography variant="body1" fontWeight="bold" gutterBottom style={{ margin: '8px', color: 'gray' }}>
                  File
                </Typography>
                <Tooltip title="Carica Documenti">
                  <IconButton component="label" color="primary" style={{ marginRight: "10px" }}>
                    <Upload />
                    <input
                      type="file"
                      hidden
                      onChange={(event) => {
                        const file = event.target.files[0];
                        if (file) {
                          handleFormChange(type, "files", [...(formData[type]?.files || []), file]);
                        }
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
              <TableContainer component={Paper} style={{ borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Table>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#333' }}>
                      <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Nome File</TableCell>
                      <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Data Inserimento</TableCell>
                      <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Azioni</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formData[type]?.files?.map((file, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ width: '33.33%', textAlign: 'center' }}>{file.name}</TableCell>
                        <TableCell style={{ width: '33.33%', textAlign: 'center' }}>{new Date(file.lastModified).toLocaleDateString()}</TableCell>
                        <TableCell style={{ width: '33.33%', textAlign: 'center' }}>
                          <Tooltip title="Elimina">
                            <IconButton
                              onClick={() => {
                                const updatedFiles = formData[type]?.files?.filter((_, i) => i !== index);
                                handleFormChange(type, "files", updatedFiles);
                              }}
                              color="error"
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                    {!formData[type]?.files?.length && (
                      <TableRow>
                        <TableCell colSpan={3} align="center" style={{ textAlign: 'center' }}>
                          Nessun file caricato.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>}

          </Grid>
        </Box>

        <Box display="flex" justifyContent="space-between" mt={2}>
          <Typography variant="h6" onClick={() => setExpanded((prev) => ({ ...prev, [type]: !prev[type] }))} style={{ cursor: "pointer" }}>
            Storico
          </Typography>
          <IconButton onClick={() => setExpanded((prev) => ({ ...prev, [type]: !prev[type] }))}>
            {expanded[type] ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
        <Collapse in={expanded[type]}>
          <TableContainer component={Paper} style={{ borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
            {type === "timbrature" && (
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: '#333' }}>
                    <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Data</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Entrata</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Totale Pause</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Uscita</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Note</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Approvazione</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history.timbrature.map((timbratura) => (
                    <TableRow key={timbratura.id}>
                      <TableCell align="center">{formatDate(new Date(timbratura.id))}</TableCell>
                      <TableCell align="center">{ts2Time(timbratura.entrata.t)}</TableCell>
                      <TableCell align="center">{calculateTotalPause(timbratura.pause)}</TableCell>
                      <TableCell align="center">{ts2Time(timbratura.uscita.t)}</TableCell>
                      <TableCell align="center">
                        {timbratura.note && timbratura.note.length > 20 ? timbratura.note.substring(0, 20) + '...' : timbratura.note}
                      </TableCell>
                      <TableCell align="center">
                        {timbratura.approvata && <CheckCircleOutline style={{ color: 'green' }} />}
                        {timbratura.approvata === false && <Cancel style={{ color: 'red' }} />}
                        {timbratura.approvata === null && <Pending style={{ color: 'orange' }} />}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {type === "event" && (
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: '#333' }}>
                    <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Tipo</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Data Da</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Data A</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Ore Da</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Ore A</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Files</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Note</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Approvazione</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history.event.map((evento) => (
                    <TableRow key={evento.id}>
                      <TableCell align="center">{evento.payload.type}</TableCell>
                      <TableCell align="center">{utils.revDate(evento.payload.dateFrom)}</TableCell>
                      <TableCell align="center">{utils.revDate(evento.payload.dateTo)}</TableCell>
                      <TableCell align="center">{ts2Time(evento.payload.timeFrom)}</TableCell>
                      <TableCell align="center">{ts2Time(evento.payload.timeTo)}</TableCell>
                      <TableCell align="center">
                        {evento.payload.file ? evento.payload.file.name : ""}
                      </TableCell>
                      <TableCell align="center">
                        {evento.payload.note && evento.payload.note.length > 20 ? evento.payload.note.substring(0, 20) + '...' : evento.payload.note}
                      </TableCell>
                      <TableCell align="center">
                        {evento.approvato && <CheckCircleOutline style={{ color: 'green' }} />}
                        {evento.approvato === false && <Cancel style={{ color: 'red' }} />}
                        {evento.approvato === null && <Pending style={{ color: 'orange' }} />}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {type === "communication" && (
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: '#333' }}>
                    <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Tipo</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Data Da</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Data A</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Ore Da</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Ore A</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Files</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>Note</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history.communication.map((comm) => (
                    <TableRow key={comm.id}>
                      <TableCell align="center">{comm.payload.type}</TableCell>
                      <TableCell align="center">{utils.revDate(comm.payload.dateFrom)}</TableCell>
                      <TableCell align="center">{utils.revDate(comm.payload.dateTo)}</TableCell>
                      <TableCell align="center">{ts2Time(comm.payload.timeFrom)}</TableCell>
                      <TableCell align="center">{ts2Time(comm.payload.timeTo)}</TableCell>
                      <TableCell align="center">
                        {comm.payload.file ? comm.payload.file.name : ""}
                      </TableCell>
                      <TableCell align="center">
                        {comm.payload.note && comm.payload.note.length > 20 ? comm.payload.note.substring(0, 20) + '...' : comm.payload.note}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>

        </Collapse>
      </DialogContent>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button onClick={() => handleDialog(type, false)}>Annulla</Button>
        <Button onClick={() => handleSave(type)} variant="contained" sx={{ ml: 2 }}>Invia</Button>
      </Box>
    </Dialog>
  );

  return (
    <Container maxWidth="lg">
      <NotificationPermissionRequest />
      <Typography variant="h4" textAlign="center" mt={3}>Area Dipendente</Typography>

      <Grid item xs={12} textAlign="center" mt={2}>
        <Tooltip title="Timbra Entrata">
          <IconButton color="primary" onClick={() => handleTimbratura('entrata')} disabled={timbraturaState !== null}>
            <CheckCircle fontSize="inherit" style={{ fontSize: 48 }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Timbra Pausa">
          <IconButton
            color="primary"
            onClick={() => handleTimbratura('pausa')}
            disabled={timbraturaState !== 'entrata' && timbraturaState !== 'rientro'}
          >
            <PauseCircleFilled fontSize="inherit" style={{ fontSize: 48 }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Timbra Rientro">
          <IconButton color="primary" onClick={() => handleTimbratura('rientro')} disabled={timbraturaState !== 'pausa'}>
            <PlayCircleFilled fontSize="inherit" style={{ fontSize: 48 }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Timbra Uscita">
          <IconButton color="primary" onClick={() => handleTimbratura('uscita')} disabled={timbraturaState !== 'rientro' && timbraturaState !== 'entrata'}>
            <ExitToApp fontSize="inherit" style={{ fontSize: 48 }} />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item xs={12} textAlign="center" mt={2}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'digital-7',
            fontSize: '1.5rem',
            letterSpacing: '0.1em',
            color: 'black',
          }}
        >
          {currentTime}
        </Typography>
      </Grid>

      <Grid item xs={12} textAlign="center" mt={2}>
        <Tooltip title="Eventi">
          <IconButton color="primary" onClick={() => handleDialog("event", true)}>
            <Event fontSize="inherit" style={{ fontSize: 40 }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Comunicazioni">
          <IconButton color="primary" onClick={() => handleDialog("communication", true)}>
            <Email fontSize="inherit" style={{ fontSize: 40 }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Timbrature">
          <IconButton color="primary" onClick={() => handleDialog("timbrature", true)}>
            <AccessTime fontSize="inherit" style={{ fontSize: 40 }} />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item xs={12} textAlign="center" mt={2}>
        {lastTimbratura && (
          <Paper elevation={0} sx={{ p: 1 }}>
            <Typography variant="h6" sx={{ color: 'black' }}>Ultima Timbratura</Typography>
            <Typography variant="body1" sx={{ color: 'gray' }}>Tipo: {lastTimbratura.type}</Typography>
            <Typography variant="body1" sx={{ color: 'gray' }}>Data: {lastTimbratura.date}</Typography>
            <Typography variant="body1" sx={{ color: 'gray' }}>Ora: {lastTimbratura.time}</Typography>
            <Typography variant="body1" sx={{ color: 'gray' }}>
              Posizione: {lastTimbratura.position ? `${lastTimbratura.position.latitude}, ${lastTimbratura.position.longitude}` : 'Non Rilevata'}
            </Typography>
            {lastTimbratura.type === 'Uscita' && (
              <Typography variant="body1" sx={{ color: 'gray', fontWeight: 'bold' }}>
                Totale Ore: {calculateTotalHours().toFixed(2)} ore
              </Typography>
            )}
          </Paper>
        )}
      </Grid>

      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
        {renderDialog("event", "Aggiungi Evento", ["Ferie", "Permesso", "Assenza", "Altro"])}
        {renderDialog("communication", "Aggiungi Comunicazione", ["Malattia", "Infortunio", "Maternità", "Congedo", "Donazione Sangue", "Altro"])}
        {renderDialog("timbrature", "Aggiungi Timbratura")}
      </LocalizationProvider>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ open: false, message: '' })}
        message={snackbar.message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            justifyContent: 'center', textAlign: 'center',
          },
          mb: 7, // Aggiunge un margine inferiore di 7 unità (16px per unità)
        }}
      />
    </Container>
  );
};

export default AreaDipendenti;
